import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import cn from "./styles.less";

export const Loader = () => {
  const { t } = useTranslation();

  return (
    <div data-qa-selector="viewerLoader" className={cn.loaderCard}>
      <Spin size="large" tip={t("bo.orderDocuments.loading")} />
    </div>
  );
};
