import { useMutation } from "react-query";

import { AccessDomain } from "@/acl";
import { getApiClient } from "@/apiClient";
import { RetailDocumentRequestCreateProjectionInput } from "@/generated/graphql";
import { useCallback } from "react";

interface Props {
  orderId: string;
}

export const useCreateDocumentRequest = ({ orderId }: Props) => {
  const { mutateAsync } = useMutation(
    getApiClient({ domain: AccessDomain.BackofficeManagement })
      .CreateDocumentRequest
  );

  return useCallback(
    async (
      input: Omit<RetailDocumentRequestCreateProjectionInput, "attachedTo">
    ) => {
      await mutateAsync({
        createProjection: {
          ...input,
          attachedTo: orderId,
        },
      });
    },
    [mutateAsync, orderId]
  );
};
