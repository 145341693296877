// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fGsUwHtTkxOyj9AEWfAK {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  height: 20vh;\n  width: 20vh;\n  background: white;\n  border-radius: 2px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DocumentViewer/Loader/styles.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,YAAA;EACA,WAAA;EAEA,iBAAA;EACA,kBAAA;EAEA,aAAA;EACA,sBAAA;EACA,uBAAA;AADF","sourcesContent":[".loaderCard {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  height: 20vh;\n  width: 20vh;\n\n  background: white;\n  border-radius: 2px;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderCard": "fGsUwHtTkxOyj9AEWfAK"
};
export default ___CSS_LOADER_EXPORT___;
