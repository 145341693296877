import { DocumentViewer } from "@/components/DocumentViewer";
import { RequestDocumentModal } from "@/components/RequestDocumentModal";
import { AddDocumentModal } from "@/features/DocumentsTab/AddDocumentModal";
import { DocumentTemplates } from "@/features/DocumentsTab/DocumentTemplates";
import { DocumentsList } from "@/features/DocumentsTab/DocumentsList";
import { useDocumentPreview } from "@/features/DocumentsTab/useDocumentPreview";
import { useDocumentTemplates } from "@/features/DocumentsTab/useDocumentTemplates";
import { useDocumentsData } from "@/features/DocumentsTab/useDocumentsData";
import { useDocumentActions } from "@/hooks/useDocumentActions";
import { useDocumentRequestsFeatureEnabled } from "@/hooks/useDocumentRequestsFeatureEnabled";
import { useDocumentViewerProps } from "@/hooks/useDocumentViewerProps";
import { useSearchDocumentRequests } from "@/hooks/useSearchDocumentRequests";
import { ClassificationModel } from "@/types/ClassificationModel";
import { dispatchEvent } from "@retail/backoffice-events";
import { ORDER_TYPES } from "@retail/order-constants";
import { Button, Space, Tabs, notification } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentRequestsCount } from "../DocumentRequestsCount";
import { DocumentRequests } from "./DocumentRequests";

const ROOT_ID = "order-documents-mfe";

interface Props {
  orderId: string;
  isModalInitiallyOpen: boolean;
}

export const DocumentsTab = ({ orderId, isModalInitiallyOpen }: Props) => {
  const [notificationApi, notificationContextHolder] =
    notification.useNotification();
  const { t } = useTranslation();
  const [isLoadingRotation, setLoadingRotation] = useState(false);

  const data = useDocumentsData(orderId);

  const documentPreview = useDocumentPreview({
    orderId,
    documents: data?.documents,
  });

  const {
    documentActions,
    documentViewerProps: { onDelete: onDocumentDelete, ...documentViewerProps },
  } = useDocumentViewerProps({
    orderId,
    notificationApi,
    onUpdateDone: data.refetch,
  });

  const isFeatureEnabledCustomerDocumentRequestBackoffice =
    useDocumentRequestsFeatureEnabled({
      orderType: documentViewerProps.orderType,
      retailCountry: documentViewerProps.retailCountry,
    });

  const commonListProps = {
    isLoading: data.isDocumentsLoading,
    onPreviewDocument: documentPreview.onPreviewDocument,
    onVerifyDocument: documentActions.verifyDocument,
    onDeleteDocument: documentActions.deleteDocument,
    onRecoverDocument: documentActions.recoverDocument,
  };

  const [isAddDocumentOpen, setAddDocumentOpen] =
    useState(isModalInitiallyOpen);
  const [requestDocumentParams, setRequestDocumentParams] =
    useState<Partial<ClassificationModel> | null>();
  const documentRequests = useSearchDocumentRequests({
    orderId,
    enabled: isFeatureEnabledCustomerDocumentRequestBackoffice,
  });

  const onCloseAddDocument = () => setAddDocumentOpen(false);
  const onCloseRequestDocument = () => setRequestDocumentParams(null);

  const onDocumentsCreated = () => {
    data.refetch();
    documentRequests.refetch();
    dispatchEvent("orderDocuments.document.created", null);
    setAddDocumentOpen(false);
  };
  const onDocumentsRequested = () => {
    documentRequests.refetch();
    setRequestDocumentParams(null);
  };

  const handleDocumentDelete = async (id: string) => {
    await onDocumentDelete(id);
    const doc = data.allDocuments?.find((it) => it.id === id);
    setRequestDocumentParams(
      doc
        ? {
            category: doc.documentType?.retailDocumentCategoryDTO?.name,
            type: doc.documentType?.id,
            documentSubTypeKey: doc.documentType?.retailDocumentSubType?.key,
          }
        : {}
    );
  };

  const { updateDocument } = useDocumentActions();
  const onRotate = async (id: string, rotationDegree: number) => {
    setLoadingRotation(true);
    await updateDocument(id, { rotationDegree });
    await data.refetch();
    setLoadingRotation(false);
  };

  const documentTemplates = useDocumentTemplates({
    context:
      data.order?.customer && data.orderFinancing
        ? {
            retailCountry: data.order.retailCountry!,
            primaryCustomerType: data.order.customer.customerType!,
            financingFlowType: data.orderFinancing.financingType,
          }
        : null,
  });

  return (
    <>
      <Tabs
        id={ROOT_ID}
        type="card"
        tabBarExtraContent={
          <Space size="large">
            {isFeatureEnabledCustomerDocumentRequestBackoffice && (
              <Button
                type="primary"
                data-qa-selector="requestDocumentButton"
                onClick={() => setRequestDocumentParams({})}
              >
                {t("bo.orderDocuments.documentRequests.button.requestDocument")}
              </Button>
            )}
            <Button
              type="primary"
              data-qa-selector="addDocumentButton"
              onClick={() => setAddDocumentOpen(true)}
            >
              {t("bo.orderDocuments.addDocument")}
            </Button>
          </Space>
        }
        style={{ width: "100%" }}
      >
        <Tabs.TabPane key="uploaded" tab={t("bo.orderDocuments.uploaded")}>
          <DocumentsList
            listType="uploaded"
            documents={data.documents?.uploaded}
            {...commonListProps}
          />
        </Tabs.TabPane>
        {isFeatureEnabledCustomerDocumentRequestBackoffice && (
          <Tabs.TabPane
            key="requested"
            tab={
              <span>
                {t("bo.orderDocuments.documentRequests.tab.requested")}{" "}
                <DocumentRequestsCount
                  orderId={orderId}
                  retailCountry={documentViewerProps.retailCountry}
                  orderType={documentViewerProps.orderType}
                />
              </span>
            }
          >
            <DocumentRequests
              documents={documentRequests.documentRequests}
              isLoading={documentRequests.isLoading}
              onDelete={documentRequests.refetch}
            />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane
          key="draft"
          tab={t("bo.orderDocuments.draft")}
          disabled={!data.documents?.draft.length}
        >
          <DocumentsList
            listType="draft"
            documents={data.documents?.draft}
            {...commonListProps}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="deleted"
          tab={t("bo.orderDocuments.deleted")}
          disabled={!data.documents?.deleted.length}
        >
          <DocumentsList
            listType="deleted"
            documents={data.documents?.deleted}
            {...commonListProps}
          />
        </Tabs.TabPane>
        {documentTemplates.length > 0 ? (
          <Tabs.TabPane
            key="templates"
            tab={t("bo.orderDocuments.tabs.templates")}
          >
            <DocumentTemplates templates={documentTemplates} />
          </Tabs.TabPane>
        ) : null}
      </Tabs>
      <DocumentViewer
        isOpen={documentPreview.initialId != null}
        allDocuments={data.allDocuments!}
        previewDocuments={documentPreview.documents}
        initialId={documentPreview.initialId}
        onClose={documentPreview.onClosePreview}
        refetchDocuments={data.refetch}
        refetchDocumentRequests={documentRequests.refetch}
        onDelete={handleDocumentDelete}
        onRotate={onRotate}
        isLoadingRotation={isLoadingRotation}
        {...documentViewerProps}
        financingType={data.orderFinancing?.financingType}
      />
      {isAddDocumentOpen && data.order?.retailCountry && (
        <AddDocumentModal
          orderId={orderId}
          retailCountry={data.order.retailCountry}
          financingType={data.orderFinancing?.financingType}
          onClose={onCloseAddDocument}
          onDocumentsCreated={onDocumentsCreated}
        />
      )}
      {requestDocumentParams &&
      data.order?.retailCountry &&
      documentViewerProps.orderType === ORDER_TYPES.STANDARD ? (
        <RequestDocumentModal
          orderId={orderId}
          retailCountry={data.order.retailCountry}
          financingType={data.orderFinancing?.financingType}
          initialClassification={requestDocumentParams}
          onClose={onCloseRequestDocument}
          onDocumentsRequested={onDocumentsRequested}
        />
      ) : null}
      {notificationContextHolder}
    </>
  );
};
