import { DocumentEntity } from "@/types/DocumentEntity";
import { Card } from "antd";
import { Dispatch } from "react";
import { Arrows } from "../Arrows";
import { ImageViewer } from "../ImageViewer";
import { ObjectViewer } from "../ObjectViewer";
import { ReturnGetFileMeta, isImageMimeType } from "../utils";
import cn from "./styles.less";

interface Props {
  currentDocUrl: string;
  currentDocName: string;
  currentDocMeta?: ReturnGetFileMeta;
  viewableDocuments?: DocumentEntity[];
  isLoadingRotation?: boolean;
  onArrow: Dispatch<number>;
  onRotate: (newAngle: number) => void;
}

export const ViewerCard = ({
  currentDocUrl,
  currentDocName,
  currentDocMeta,
  viewableDocuments,
  isLoadingRotation,
  onArrow,
  onRotate,
}: Props) => {
  return (
    <Card
      data-qa-selector="viewerCard"
      className={cn.section}
      bodyStyle={{ height: "100%" }}
    >
      {currentDocMeta?.mimeType && isImageMimeType(currentDocMeta.mimeType) ? (
        <ImageViewer
          key={currentDocUrl}
          fullUrl={currentDocUrl}
          fileName={currentDocName}
          isLoadingRotation={isLoadingRotation}
          rotation={currentDocMeta.rotationDegree}
          onRotate={onRotate}
        />
      ) : (
        <ObjectViewer
          fullUrl={currentDocUrl}
          fileExtension={currentDocMeta?.fileExtension}
          fileName={currentDocName}
          mimeType={currentDocMeta?.mimeType}
        />
      )}
      {viewableDocuments?.length && <Arrows onClick={onArrow} />}
    </Card>
  );
};
