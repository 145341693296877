import { getApiClient } from "@/apiClient";
import { Nil } from "@/types";
import { useMutation } from "react-query";

interface Props {
  orderId: string;
}

export function useSaveVisitingLog({ orderId }: Props) {
  const { mutateAsync } = useMutation((fullUrl: string | Nil) =>
    getApiClient({ domain: null }).SaveVisitingLog({
      model: {
        eventType: "BO_DOCUMENT",
        eventSource: fullUrl,
        details: {
          orderId,
          pageUrl: window.location.href,
        },
      },
    })
  );

  return mutateAsync;
}
