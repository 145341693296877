import React, { useCallback, useMemo } from "react";
import { Upload } from "antd";
import type { UploadChangeParam, UploadFile } from "antd/es/upload";

interface UploadControlProps {
  children: React.ReactNode;
  accept: string[];
  maxFileSizeBytes: number;
  value?: UploadFile[];
  onChange?: (value: UploadFile[]) => void;
  onOversizedFileSelected: (files: UploadFile) => void;
}

export const UploadControl = ({
  children,
  accept,
  maxFileSizeBytes,
  value,
  onChange,
  onOversizedFileSelected,
}: UploadControlProps) => {
  const onHandleChange = ({ fileList, file, event }: UploadChangeParam) =>
    onChange?.(fileList);

  const beforeUpload = (file: UploadFile) => {
    if (
      file.size != null &&
      file.size <= maxFileSizeBytes &&
      file.type != null &&
      accept.includes(file.type)
    ) {
      return false;
    } else {
      onOversizedFileSelected(file);
      return Upload.LIST_IGNORE;
    }
  };

  return (
    <Upload
      type="drag"
      multiple
      accept={accept.join(", ")}
      fileList={value ?? []}
      beforeUpload={beforeUpload}
      onChange={onHandleChange}
    >
      {children}
    </Upload>
  );
};
