import { DocumentDeleteConfirmation } from "@/components/DocumentDeleteConfirmation";
import { RecoverConfirmation } from "@/components/RecoverConfirmation";
import { DocumentState } from "@/constants/DocumentState";
import { DocumentEntity } from "@/types/DocumentEntity";
import { DeleteFilled, MinusOutlined, ToolFilled } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import cn from "./styles.less";

interface CellActionsProps {
  doc: Pick<DocumentEntity, "state" | "meta">;
  onDelete: () => Promise<unknown>;
  onRecover: () => Promise<unknown>;
}

export const CellActions = ({
  doc: { state, meta },
  onDelete,
  onRecover,
}: CellActionsProps) => {
  const { t } = useTranslation();

  if (!meta.isEditAllowed) {
    return <MinusOutlined />;
  }

  switch (state) {
    case DocumentState.UPLOADED:
    case DocumentState.DRAFT:
      return (
        <DocumentDeleteConfirmation onConfirm={onDelete}>
          <Button
            type="primary"
            danger
            data-qa-selector="deleteButton"
            icon={<DeleteFilled />}
          />
        </DocumentDeleteConfirmation>
      );

    case DocumentState.DELETED:
      return (
        <RecoverConfirmation onConfirm={onRecover}>
          <Button
            className={cn.recoverButton}
            size="small"
            data-qa-selector="recoverButton"
            icon={<ToolFilled />}
          >
            {t("bo.orderDocuments.recover")}
          </Button>
        </RecoverConfirmation>
      );

    default:
      return <MinusOutlined />;
  }
};
