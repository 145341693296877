import { Popconfirm as AntdPopconfirm, type PopconfirmProps } from "antd";
import { useTranslation } from "react-i18next";

export { PopconfirmProps };

export const PopConfirm = (props: PopconfirmProps) => {
  const { t } = useTranslation();
  return (
    <AntdPopconfirm
      okText={t("bo.orderDocuments.confirm")}
      placement="topRight"
      arrowPointAtCenter
      okButtonProps={{
        // @ts-ignore
        "data-qa-selector": "confirm",
      }}
      {...props}
    />
  );
};
