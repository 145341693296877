// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BqwHXyv6eFnWwT3Rkdc3 {\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  inset: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DocumentViewer/ObjectViewer/styles.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,kBAAA;EACA,QAAA;AACF","sourcesContent":[".section {\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  inset: 0;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "BqwHXyv6eFnWwT3Rkdc3"
};
export default ___CSS_LOADER_EXPORT___;
