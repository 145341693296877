import { useSaveVisitingLog } from "@/hooks/useSaveVisitingLog";
import { DocumentEntity } from "@/types/DocumentEntity";
import { useState } from "react";

interface Props {
  orderId: string;
  documents: DocumentEntity[];
}

export const useDocumentPreview = ({ orderId, documents }: Props) => {
  const saveLogMutation = useSaveVisitingLog({ orderId });
  const [initialId, setInitialId] = useState<string>();

  function onPreviewDocument(id: string) {
    const doc = documents.find((x) => x.id === id);

    if (!doc) {
      return;
    }

    saveLogMutation(doc.fullUrl);
    setInitialId(doc.id);
  }

  const onClosePreview = () => setInitialId(undefined);

  return {
    initialId,
    onPreviewDocument,
    onClosePreview,
  };
};
