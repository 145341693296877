import { ComponentProps, ReactNode } from "react";

interface Props extends ComponentProps<"div"> {
  label: ReactNode;
  value: ReactNode;
}

export const Income = ({ label, value, ...props }: Props) => {
  return value ? (
    <div {...props}>
      <span>{label}:</span> <span data-qa-selector="incomeValue">{value}</span>
    </div>
  ) : null;
};
