import { Permission, useHasPermission } from "@/acl";
import { COUNTRIES } from "@retail/i18n/constants";
import { useCallback } from "react";

import { DOCUMENT_CATEGORIES, DOCUMENT_TYPES } from "@/constants/documents";
import { Nil } from "@/types";
import { SearchDocumentEntity } from "@/types/SearchDocumentEntity";
import { FINANCING_TYPES } from "@retail/order-financing/src/constants";

const INTERNAL_FINANCING_PERMISSIONS_BY_DOCUMENT_TYPE = {
  [DOCUMENT_TYPES.DIRECT_DEBIT_MANDATE]:
    "internalFinancingDocumentOfTypeDirectDebitMandateView",
  [DOCUMENT_TYPES.RISK_SCORECARD]:
    "internalFinancingDocumentOfTypeRiskScoreCardView",
};

const DIGITAL_CAR_REGISTRATION_DOCUMENT_TYPES = [
  DOCUMENT_TYPES.DATA_PRIVACY,
  DOCUMENT_TYPES.KBA_APPROVAL_DECISION,
  DOCUMENT_TYPES.KBA_FEE_NOTICE,
  DOCUMENT_TYPES.KBA_TEMPORARY_REGISTRATION,
  DOCUMENT_TYPES.PROOF_OF_LICENSE_PLATE_RESERVATION,
  DOCUMENT_TYPES.SEPA_MANDAT,
  DOCUMENT_TYPES.ATTORNEY,
];

interface Props {
  retailCountry: string | Nil;
  financingType: string | Nil;
}

export const useDocumentsPermissionsChecker = ({
  retailCountry,
  financingType,
}: Props) => {
  const hasPermission = useHasPermission();

  return useCallback(
    ({ documentType }: Pick<SearchDocumentEntity, "documentType">) => {
      if (!retailCountry) {
        return { isContentViewAllowed: false, isEditAllowed: false };
      }

      const documentTypeKey = documentType?.key!;
      if (
        financingType === FINANCING_TYPES.INTERNAL &&
        documentTypeKey in INTERNAL_FINANCING_PERMISSIONS_BY_DOCUMENT_TYPE
      ) {
        const isAllowed = hasPermission(
          INTERNAL_FINANCING_PERMISSIONS_BY_DOCUMENT_TYPE[
            documentTypeKey
          ] as Permission
        );

        return { isContentViewAllowed: isAllowed, isEditAllowed: isAllowed };
      }

      if (
        documentType?.retailDocumentCategoryDTO?.name ===
          DOCUMENT_CATEGORIES.FINANCE &&
        financingType === FINANCING_TYPES.EXTERNAL
      ) {
        return {
          isContentViewAllowed:
            hasPermission("externalFinancingDocumentOfCategoryFinancingView") ||
            hasPermission("externalFinancingDocumentOfCategoryFinancingEdit"),
          isEditAllowed: hasPermission(
            "externalFinancingDocumentOfCategoryFinancingEdit"
          ),
        };
      }

      if (
        retailCountry === COUNTRIES.DE &&
        DIGITAL_CAR_REGISTRATION_DOCUMENT_TYPES.includes(documentTypeKey)
      ) {
        const isAllowed = hasPermission(
          "digitalCarRegistrationDocumentsViewEdit"
        );

        return { isContentViewAllowed: isAllowed, isEditAllowed: isAllowed };
      }

      return { isContentViewAllowed: true, isEditAllowed: true };
    },
    [hasPermission, retailCountry, financingType]
  );
};
