import { Nil } from "@/types";
import { OrderAddressModel } from "@/types/OrderDocumentDetailsModel";
import { ComponentProps, ReactNode } from "react";
import { toLocalizedCountry } from "./utils";

interface Props extends ComponentProps<"section"> {
  label?: ReactNode;
  address?: OrderAddressModel | Nil;
}

export const AddressInfo = ({ label, address, ...props }: Props) => {
  if (!address) {
    return null;
  }

  const { houseNumber, houseNumberAdditive, zipcode, city, country } = address;
  const street = [address.street, houseNumber, houseNumberAdditive]
    .filter(Boolean)
    .join(" ");

  return (
    <section {...props}>
      <div>
        <strong>{label}:</strong>
      </div>
      <div data-qa-selector="addressInfo">
        {[street, zipcode, city, toLocalizedCountry(country)]
          .filter(Boolean)
          .join(", ")}
      </div>
    </section>
  );
};
