import { OrderFinancingType } from "@/generated/graphql";

type RecordValue<K extends keyof any> = {
  [P in K]: P;
};

export const ORDER_FINANCING_TYPE: RecordValue<OrderFinancingType> = {
  EXTERNAL: "EXTERNAL",
  INTERNAL: "INTERNAL",
};
