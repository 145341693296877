import {
  OrderAddressModel,
  OrderCustomerModel,
} from "@/types/OrderDocumentDetailsModel";
import { Space } from "antd";
import { useTranslation } from "react-i18next";
import { AddressInfo } from "../AddressInfo";
import { CustomerInfo } from "../CustomerInfo";
import cn from "./styles.less";

interface Props {
  coOwner?: OrderCustomerModel;
  coOwnerAddress?: OrderAddressModel;
}

export const CoOwnerInfo = ({ coOwner, coOwnerAddress }: Props) => {
  const { t } = useTranslation();

  return (
    <Space className={cn.section} direction="vertical" size="small">
      <div>
        <strong>{t("bo.orderDocuments.viewer.orderDetails.coOwner")}:</strong>
        <CustomerInfo customer={coOwner} />
      </div>
      <AddressInfo
        data-qa-selector="coOwnerAddress"
        label={t("bo.orderDocuments.viewer.orderDetails.coOwnerAddress")}
        address={coOwnerAddress}
      />
    </Space>
  );
};
