import { LabeledRow } from "@/components/LabeledRow";
import { OrderDocumentDetailsModel } from "@/types/OrderDocumentDetailsModel";
import { useTranslation } from "react-i18next";

export enum EMPLOYMENT_STATUS {
  EMPLOYED = "EMPLOYED",
}

interface Props
  extends Pick<
    OrderDocumentDetailsModel,
    "financeRiskEvaluationByOrderFinancing"
  > {}

export const FinanceRiskEvaluationByOrderFinancing = ({
  financeRiskEvaluationByOrderFinancing,
}: Props) => {
  const { t } = useTranslation();

  if (!financeRiskEvaluationByOrderFinancing) {
    return null;
  }

  const {
    contractType,
    employedUnderLocalWorkContract,
    employmentStatus,
    sameEmployerOverTimePeriod,
  } = financeRiskEvaluationByOrderFinancing;

  return (
    <div>
      <LabeledRow
        label={t("bo.orderDocuments.viewer.orderDetails.employmentStatus")}
      >
        {employmentStatus}
      </LabeledRow>
      <LabeledRow
        label={t(
          "bo.orderDocuments.viewer.orderDetails.lengthOfEmployment.title"
        )}
      >
        {employmentStatus !== EMPLOYMENT_STATUS.EMPLOYED
          ? null
          : t(
              "bo.orderDocuments.viewer.orderDetails.lengthOfEmployment.value",
              {
                prefix: sameEmployerOverTimePeriod
                  ? t("bo.common.more")
                  : t("bo.common.less"),
              }
            )}
      </LabeledRow>
      <LabeledRow
        label={t("bo.orderDocuments.viewer.orderDetails.contractType")}
      >
        {contractType}
      </LabeledRow>
      <LabeledRow
        label={t("bo.orderDocuments.viewer.orderDetails.localWorkContract")}
      >
        {employedUnderLocalWorkContract
          ? t("bo.common.yes")
          : t("bo.common.no")}
      </LabeledRow>
    </div>
  );
};
