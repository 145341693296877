import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { PopConfirm, PopconfirmProps } from "./PopConfirm";

interface Props extends Partial<PopconfirmProps> {
  onConfirm: () => Promise<unknown>;
}

export const VerifyConfirmation = ({
  onConfirm,
  children,
  ...rest
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <PopConfirm
      title={t(
        "bo.orderDocuments.viewer.orderDetails.verifyConfirmation.title"
      )}
      onConfirm={onConfirm}
      {...rest}
    >
      {children}
    </PopConfirm>
  );
};
