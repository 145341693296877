import { FEATURE } from "@/constants/feature";
import { Nil } from "@/types";
import { ORDER_TYPES } from "@retail/order-constants";
import { useFeatures } from "./useFeatures";

interface Props {
  retailCountry: string | Nil;
  orderType: string | Nil;
}

export function useDocumentRequestsFeatureEnabled({
  retailCountry,
  orderType,
}: Props) {
  const { isFeatureEnabled } = useFeatures({
    country: retailCountry,
    names: [FEATURE.CUSTOMER_DOCUMENT_REQUESTS_BACKOFFICE],
  });

  return Boolean(
    isFeatureEnabled(FEATURE.CUSTOMER_DOCUMENT_REQUESTS_BACKOFFICE) &&
      orderType === ORDER_TYPES.STANDARD
  );
}
