import { AccessDomain, useHasPermission } from "@/acl";
import { OrderFinancingType } from "@/generated/graphql";
import { Nil } from "@/types";
import { useQuery } from "react-query";

import { getApiClient } from "@/apiClient";
import { ClassificationOption } from "@/components/ClassificationFields/types";
import {
  DOCUMENT_CATEGORIES,
  DOCUMENT_TOP_CATEGORY_ORDER,
} from "@/constants/documents";
import { CategoryEntity } from "@/types/CategoryEntity";
import { DocumentEntity } from "@/types/DocumentEntity";
import { useTranslation } from "react-i18next";
import { FINANCING_TYPES } from "@retail/order-financing/src/constants";

interface Props {
  retailCountry: string;
  financingType: OrderFinancingType | Nil;
  document?: DocumentEntity;
}

export const useCategoriesData = ({
  retailCountry,
  financingType,
  document,
}: Props) => {
  const { t } = useTranslation();
  const hasPermission = useHasPermission();
  const query = useQuery(
    ["document-categories", retailCountry],
    () =>
      getApiClient({
        domain: AccessDomain.BackofficeManagement,
      }).LoadDocumentCategories({
        topCategory: DOCUMENT_TOP_CATEGORY_ORDER,
        country: retailCountry,
      }),
    {
      staleTime: 60 * 1000,
      select: ({ documentCategories }) => {
        const categories = (documentCategories?.categories ??
          []) as CategoryEntity[];

        const subTypesByType: Record<string, ClassificationOption[]> = {};

        const typesByCategory = categories.reduce<
          Record<string, ClassificationOption[]>
        >((result, { name, documentTypes }) => {
          result[name!] = documentTypes.map((type): ClassificationOption => {
            subTypesByType[type?.id!] =
              type.subTypes?.map(
                (subType): ClassificationOption => ({
                  label: t(`bo.orderDocuments.documentSubType.${subType?.key}`),
                  value: String(subType?.key),
                  "data-qa-selector": "option",
                  "data-qa-selector-option-label": String(subType?.name),
                })
              ) ?? [];

            return {
              label: t(`bo.orderDocuments.documentType.${type.key}`),
              value: String(type.id),
              "data-qa-selector": "option",
              "data-qa-selector-option-key": String(type.key),
              "data-qa-selector-option-label": String(type.name),
            };
          });
          return result;
        }, {});

        if (document) {
          const currTypes =
            typesByCategory[
              document.documentType?.retailDocumentCategoryDTO?.name!
            ];

          if (Array.isArray(currTypes)) {
            const currType = currTypes.find(
              (x) => x.value === document.documentType?.id
            );

            if (!currType) {
              typesByCategory[
                document.documentType?.retailDocumentCategoryDTO?.name!
              ].push({
                label: t(
                  `bo.orderDocuments.documentType.${document.documentType?.key}`
                ),
                value: String(document.documentType?.id),
                disabled: true,
                "data-qa-selector": "option",
                "data-qa-selector-option-key": String(
                  document.documentType?.key
                ),
                "data-qa-selector-option-label": String(
                  document.documentType?.name
                ),
              });
            }
          }
        }

        return {
          categories: categories.map(({ name }): ClassificationOption => {
            const isAllowed =
              name === DOCUMENT_CATEGORIES.FINANCE &&
              financingType === FINANCING_TYPES.EXTERNAL
                ? hasPermission(
                    "externalFinancingDocumentOfCategoryFinancingEdit"
                  )
                : true;
            const label = t(`bo.orderDocuments.documentCategory.${name}`);

            return {
              label,
              value: String(name),
              title: "",
              disabled: !isAllowed,
              "data-qa-selector": "option",
              "data-qa-selector-option-label": label,
            };
          }),
          typesByCategory,
          subTypesByType,
        };
      },
    }
  );

  return {
    data: query.data,
    isLoading: query.isLoading,
  };
};
