import { AccessDomain } from "@/acl";
import { getApiClient } from "@/apiClient";
import { DocumentState } from "@/constants/DocumentState";
import { RetailDocumentUpdateProjectionInput } from "@/generated/graphql";
import { NotificationInstance } from "antd/lib/notification";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

interface UseDocumentActionsParams {
  notificationApi?: NotificationInstance;
  onUpdateDone?: VoidFunction;
}

type UpdateCallbackParams = {
  id: string;
  successMessage: string;
  document: RetailDocumentUpdateProjectionInput;
};

export const useDocumentActions = ({
  notificationApi,
  onUpdateDone,
}: UseDocumentActionsParams = {}) => {
  const { t } = useTranslation();
  const { mutateAsync, isLoading } = useMutation(
    getApiClient({ domain: AccessDomain.BackofficeManagement }).PatchDocument
  );

  const updateCallback = useCallback(
    async ({ id, document, successMessage }: UpdateCallbackParams) => {
      const { patchDocument } = await mutateAsync({ id, document });
      notificationApi?.success({
        message: successMessage,
      });
      onUpdateDone?.();

      return patchDocument;
    },
    [onUpdateDone, mutateAsync, notificationApi]
  );

  const deleteDocument = (id: string) =>
    updateCallback({
      id,
      successMessage: t("bo.orderDocuments.deleted.successMessage"),
      document: { state: DocumentState.DELETED },
    });

  const recoverDocument = (id: string) =>
    updateCallback({
      id,
      successMessage: t("bo.orderDocuments.recover.successMessage"),
      document: { state: DocumentState.UPLOADED },
    });

  const verifyDocument = useCallback(
    (id: string) =>
      updateCallback({
        id,
        successMessage: t(
          "bo.ordersPage.orderDetails.tabs.verification.section.documents.verifySuccess"
        ),
        document: { verified: true },
      }),
    [updateCallback, t]
  );

  const updateDocument = (
    id: string,
    document: RetailDocumentUpdateProjectionInput
  ) =>
    updateCallback({
      id,
      document,
      successMessage: t("bo.orderDocuments.viewer.notification.updated"),
    });

  return {
    deleteDocument,
    recoverDocument,
    verifyDocument,
    updateDocument,
    isLoading,
  };
};
