import { AccessDomain } from "@/acl";
import { getApiClient } from "@/apiClient";
import { ORDER_FINANCING_TYPE } from "@/constants/OrderFinancingType";
import {
  EmploymentDetailsQuery,
  LoadOrderInfoQuery,
  RetailAddressProjection,
  UpdateDocumentClassificationMutationVariables,
} from "@/generated/graphql";
import { useUpdateDocumentClassification } from "@/hooks/useUpdateDocumentClassification";
import { Nil } from "@/types";
import { ClassificationModel } from "@/types/ClassificationModel";
import { OrderDocumentDetailsModel } from "@/types/OrderDocumentDetailsModel";
import { COUNTRIES } from "@retail/i18n/constants";
import { ADDRESS_TYPES, AddressType } from "@retail/order-constants";
import { CUSTOMER_RANKS } from "@retail/order-constants/src/customerRanks";
import { NotificationInstance } from "antd/lib/notification";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDocumentActions } from "../useDocumentActions";

function getAddressByTypeAndCustomerId(
  value: Partial<LoadOrderInfoQuery["orderAddresses"]>
) {
  return (type: AddressType, customerId: string | Nil) => {
    const address = value?.find(
      (x) => x?.addressType === type && x.customerId === customerId
    );

    return address?.address || (address?.branch as RetailAddressProjection);
  };
}

function getAddressByCustomerId(
  value: Partial<LoadOrderInfoQuery["orderAddresses"]>
) {
  return (customerId: string | Nil) => {
    const address = value?.find((x) => x?.customerId === customerId);

    return address?.address || (address?.branch as RetailAddressProjection);
  };
}

function getNameFromAddress(
  orderAddresses: Partial<LoadOrderInfoQuery["orderAddresses"]>,
  userId: string | Nil
) {
  const { firstName, lastName } =
    orderAddresses?.find((x) => x?.customerId === userId)?.address ?? {};

  return firstName && lastName ? { firstName, lastName } : undefined;
}

interface Props {
  orderId: string;
  notificationApi: NotificationInstance;
  onUpdateDone?: () => Promise<unknown>;
}

export function useDocumentViewerProps({
  orderId,
  notificationApi,
  onUpdateDone,
}: Props) {
  const { t } = useTranslation();
  const updateDocumentClassification = useUpdateDocumentClassification();
  const { data: orderData } = useQuery(["LoadOrderInfo", orderId], () =>
    getApiClient().LoadOrderInfo({ orderId })
  );

  const documentActions = useDocumentActions({
    notificationApi,
    onUpdateDone,
  });

  const orderFinancing = orderData?.orderFinancing;
  const orderFinancingId = orderFinancing?.id;
  const isExternal =
    orderFinancing?.financingType === ORDER_FINANCING_TYPE.EXTERNAL;
  const isInternal =
    orderFinancing?.financingType === ORDER_FINANCING_TYPE.INTERNAL;
  const isItaly = orderData?.order?.retailCountry === COUNTRIES.IT;

  const employmentDetailsQuery = useQuery<
    EmploymentDetailsQuery,
    { response?: { data?: EmploymentDetailsQuery } }
  >(
    ["externalRiskUserInfo", orderFinancingId],
    () =>
      getApiClient({
        domain: AccessDomain.OrderFinancingRiskManagement,
      }).EmploymentDetails({
        orderFinancingId,
        isExternal,
        isInternal,
        isItaly,
      }),
    {
      enabled: isExternal || isInternal,
      retry: false,
      /**
       * @description errors will be ignored for now because we can not filter out errors Not Found.
       * Not Found is coming then this handling will be updated
       */
      onError() {},
    }
  );

  const employmentDetails =
    employmentDetailsQuery.data ?? employmentDetailsQuery.error?.response?.data;

  const documentViewerProps = useMemo((): Omit<
    OrderDocumentDetailsModel,
    keyof ClassificationModel
  > => {
    const orderAddresses = orderData?.orderAddresses;
    const getter = getAddressByTypeAndCustomerId(orderAddresses!);
    const borrower = employmentDetails?.borrower;
    const coBorrower = employmentDetails?.coBorrower;

    const coBorrowerName = getNameFromAddress(
      orderAddresses!,
      coBorrower?.userId
    );

    const customer = orderData?.retailCustomer?.find(
      (x) => x?.type === CUSTOMER_RANKS.PRIMARY
    );
    const coOwner = orderData?.retailCustomer?.find(
      (x) => x?.type === CUSTOMER_RANKS.SECONDARY
    );

    return {
      borrower,
      coBorrower,
      coBorrowerName,
      customer,
      coOwner,
      orderType: orderData?.order?.type!,
      retailCountry: orderData?.order?.retailCountry!,
      financingType: orderData?.orderFinancing?.financingType,
      currencyCode: orderData?.order?.totalPriceGross?.currencyCode,
      creditScoreDataByOrderFinancing:
        employmentDetails?.creditScoreDataByOrderFinancing,
      financeRiskEvaluationByOrderFinancing:
        employmentDetails?.financeRiskEvaluationByOrderFinancing,
      billingAddress: getter(ADDRESS_TYPES.BILLING, customer?.id),
      documentDeliveryAddress: getter(
        ADDRESS_TYPES.DOCUMENT_SHIPPING,
        customer?.id
      ),
      shippingAddress: getter(ADDRESS_TYPES.SHIPPING, customer?.id),
      carRegistrationAddress: getter(
        ADDRESS_TYPES.CAR_REGISTRATION,
        customer?.id
      ),
      coBorrowerAddress: getter(
        ADDRESS_TYPES.BILLING,
        employmentDetails?.coBorrower?.userId
      ),
      previosHome: getter(ADDRESS_TYPES.PREVIOUS_HOME, customer?.id),
      coOwnerAddress: getAddressByCustomerId(orderAddresses!)(coOwner?.id),
    };
  }, [employmentDetails, orderData]);

  async function onDocumentViewerUpdate(
    values: UpdateDocumentClassificationMutationVariables
  ) {
    const { updateDocumentClassification: data } =
      await updateDocumentClassification(values);
    await onUpdateDone?.();
    try {
      notificationApi.success({
        message: t("bo.orderDocuments.viewer.notification.updated"),
      });
    } catch (error) {
      window.console.error(error);
    }

    return data;
  }

  return {
    documentActions,
    documentViewerProps: {
      ...documentViewerProps,
      onVerify: documentActions.verifyDocument,
      onRecover: documentActions.recoverDocument,
      onDelete: documentActions.deleteDocument,
      onUpdate: onDocumentViewerUpdate,
    },
  };
}
