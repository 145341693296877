import { OrderDocumentDetailsModel } from "@/types/OrderDocumentDetailsModel";
import { formatCurrency } from "@retail/backoffice-ui/src/utils/formatCurrency";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Borrower } from "./Borrower";
import { FinanceRiskEvaluationByOrderFinancing } from "./FinanceRiskEvaluationByOrderFinancing";
import { Income } from "./Income";
import cn from "./styles.less";

interface Props
  extends Pick<
    OrderDocumentDetailsModel,
    | "borrower"
    | "coBorrower"
    | "coBorrowerName"
    | "creditScoreDataByOrderFinancing"
    | "financeRiskEvaluationByOrderFinancing"
    | "currencyCode"
  > {
  label?: ReactNode;
  coOwnerInfo?: ReactNode;
}

export const EmploymentDetails = ({
  label,
  borrower,
  coBorrower,
  coBorrowerName,
  creditScoreDataByOrderFinancing,
  financeRiskEvaluationByOrderFinancing,
  currencyCode,
  coOwnerInfo,
}: Props) => {
  const { t } = useTranslation();

  if (
    !borrower &&
    !coBorrower &&
    !creditScoreDataByOrderFinancing &&
    !financeRiskEvaluationByOrderFinancing &&
    !coOwnerInfo
  ) {
    return null;
  }

  const { monthlyNetIncomeMinorUnits } = creditScoreDataByOrderFinancing ?? {};

  const netIncome =
    Number.isFinite(monthlyNetIncomeMinorUnits) && currencyCode
      ? formatCurrency({
          amountMinorUnits: monthlyNetIncomeMinorUnits,
          currencyCode,
        })
      : null;

  return (
    <section>
      <div>
        <strong>{label}:</strong>
      </div>
      <Borrower
        data-qa-selector="borrower"
        borrower={borrower}
        currencyCode={currencyCode}
      />
      {coOwnerInfo}
      {coBorrower ? (
        <div className={cn.coBorrowerTitle}>
          <strong>
            {t(
              "bo.orderDocuments.viewer.orderDetails.employmentDetails.coBorrower.title"
            )}
            :
          </strong>
        </div>
      ) : null}
      <Borrower
        data-qa-selector="coBorrower"
        borrower={coBorrower}
        coBorrowerName={coBorrowerName}
        currencyCode={currencyCode}
      />
      <FinanceRiskEvaluationByOrderFinancing
        financeRiskEvaluationByOrderFinancing={
          financeRiskEvaluationByOrderFinancing
        }
      />
      <Income
        data-qa-selector="netIncome"
        label={t(
          "bo.orderDocuments.viewer.orderDetails.employmentDetails.netIncome"
        )}
        value={netIncome}
      />
    </section>
  );
};
