import { DocumentRequestDeleteConfirmation } from "@/components/DocumentRequestDeleteConfirmation";
import { DocumentRequestEntity } from "@/types/DocumentRequestEntity";
import { TableCell } from "@retail/backoffice-ui/src/PaginatedTable/TableCell";
import { TableColumnsType } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CreatedBy } from "./CreatedBy";

interface Props {
  onDelete: (id: string) => Promise<unknown>;
}

export function useColumns({ onDelete }: Props) {
  const { t } = useTranslation();

  return useMemo(
    (): TableColumnsType<DocumentRequestEntity> => [
      {
        key: "id",
        title: "#",
        width: "2%",
        render: (_, __, idx) => idx + 1,
      },
      {
        dataIndex: ["documentType", "retailDocumentCategoryDTO", "name"],
        title: t(
          "bo.ordersPage.orderDetails.tabs.verification.section.documents.category"
        ),
        render: (value) => (
          <TableCell data-qa-selector="category">
            {t(`bo.orderDocuments.documentCategory.${value}`)}
          </TableCell>
        ),
      },
      {
        dataIndex: ["documentType", "key"],
        title: t(
          "bo.ordersPage.orderDetails.tabs.verification.section.documents.type"
        ),
        render: (value) => (
          <TableCell data-qa-selector="type">
            {value ? t(`bo.orderDocuments.documentType.${value}`) : null}
          </TableCell>
        ),
      },
      {
        dataIndex: ["documentType", "retailDocumentSubType", "key"],
        title: t(
          "bo.ordersPage.orderDetails.tabs.verification.section.documents.subType"
        ),
        render: (value) => (
          <TableCell data-qa-selector="subType">
            {value ? t(`bo.orderDocuments.documentSubType.${value}`) : null}
          </TableCell>
        ),
      },
      {
        dataIndex: "requestReason",
        title: t("bo.orderDocuments.requestDocuments.form.field.reason"),
        render: (value) => (
          <TableCell data-qa-selector="requestReason">{value}</TableCell>
        ),
      },
      {
        dataIndex: "internalComment",
        title: t("bo.orderDocuments.documentRequests.table.comment"),
        render: (value) => (
          <TableCell data-qa-selector="internalComment">{value}</TableCell>
        ),
      },
      {
        dataIndex: "createdOn",
        width: 146,
        title: t("bo.orderDocuments.documentRequests.table.createdOn"),
        render: (value, { createdBy }) => (
          <TableCell data-qa-selector="createdOn">
            {value && createdBy ? (
              <CreatedBy createdOn={value} createdBy={createdBy} />
            ) : null}
          </TableCell>
        ),
      },
      {
        dataIndex: "id",
        title: t(
          "bo.ordersPage.orderDetails.tabs.verification.section.documents.action"
        ),
        render: (id) => (
          <>
            <DocumentRequestDeleteConfirmation
              onConfirm={() => onDelete(id)}
              data-qa-selector="deleteButton"
            />
          </>
        ),
      },
    ],
    [t, onDelete]
  );
}
