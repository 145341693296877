import { LANGUAGES } from "./languages";

export const getLocale = (language: string) =>
  LANGUAGES[language as keyof typeof LANGUAGES] || LANGUAGES.en;

export function i18nDetectLng() {
  let language = localStorage.getItem(process?.env?.LANGUAGE_KEY!);

  if (!Object.keys(LANGUAGES).includes(language!)) {
    language = process.env.LANGUAGE_KEY!;
  }

  return language?.toLowerCase();
}
