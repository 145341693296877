import { useTranslation } from "react-i18next";
import { Button, Table, TableColumnType } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import { DocumentTemplateItem } from "../types";

interface DocumentTemplatesProps {
  templates: DocumentTemplateItem[];
}

export const DocumentTemplates = ({ templates }: DocumentTemplatesProps) => {
  const { t } = useTranslation();

  const columns: TableColumnType<DocumentTemplateItem>[] = [
    {
      key: "label",
      title: t("bo.orderDocuments.documentTemplates.table.title"),
      render: (_, item) => (
        <Button
          type="link"
          href={item.fullUrl}
          target="_blank"
          rel="noreferrer"
        >
          {item.title}
        </Button>
      ),
    },
    {
      key: "fullUrl",
      title: t("bo.orderDocuments.documentTemplates.table.fullUrl"),
      width: 30,
      align: "center",
      render: (_, item) => (
        <Button
          type="primary"
          download
          icon={<DownloadOutlined />}
          href={item.fullUrl}
          target="_blank"
        />
      ),
    },
  ];

  return <Table dataSource={templates} columns={columns} pagination={false} />;
};
