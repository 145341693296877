import { getApiClient } from "@/apiClient";
import { DocumentState } from "@/constants/DocumentState";
import { MAX_PAGE_SIZE } from "@/constants/page";
import { useDocumentsPermissionsChecker } from "@/hooks/useDocumentsPermissionsChecker";
import { Nil } from "@/types";
import { DocumentEntity } from "@/types/DocumentEntity";
import { SearchDocumentEntity } from "@/types/SearchDocumentEntity";
import {
  FilterType,
  createFilter,
  createFilterPayload,
} from "@retail/gql-utils";
import { useMemo } from "react";
import { useQuery } from "react-query";

interface Props {
  orderId: string;
  retailCountry: string | Nil;
  financingType: string | Nil;
}

export function useSummaryVerificationDocuments({
  orderId,
  retailCountry,
  financingType,
}: Props) {
  const permissionsChecker = useDocumentsPermissionsChecker({
    retailCountry,
    financingType,
  });

  const { data, isLoading, refetch } = useQuery(
    ["useSummaryVerificationDocuments", orderId],
    () =>
      getApiClient().LoadDocuments({
        search: createFilterPayload({
          filter: createFilter(undefined, FilterType.AND, [
            createFilter("attachedTo", FilterType.IN, [orderId]),
            createFilter("state", FilterType.NOT_EQUAL, DocumentState.DELETED),
            createFilter(undefined, FilterType.OR, [
              createFilter(
                "documentType.documentCategory.name",
                FilterType.IN,
                ["CUSTOMER", "COMPANY", "FINANCE"]
              ),
              createFilter("documentType.key", FilterType.IN, [
                "ATTACHED_FROM_EMAIL_REPLY",
              ]),
            ]),
          ]),
          pageSize: MAX_PAGE_SIZE,
        }),
      }),
    { enabled: Boolean(orderId && retailCountry) }
  );

  const documents = useMemo(
    () =>
      data?.searchDocuments?.entities?.map(
        (x) =>
          ({
            ...x,
            meta: permissionsChecker(x as SearchDocumentEntity),
          } as DocumentEntity)
      ) ?? [],
    [data?.searchDocuments?.entities, permissionsChecker]
  );

  return {
    documents,
    isLoading,
    refetch,
  };
}
