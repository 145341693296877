import { ClassificationModel } from "@/types/ClassificationModel";
import { Form, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { ClassificationOption } from "./types";

const DEFAULT_OPTIONS: ClassificationOption[] = [];

interface Props {
  categories: ClassificationOption[];
  typesByCategory: Record<string, ClassificationOption[]>;
  subTypesByType: Record<string, ClassificationOption[]>;
  formApi: FormInstance<ClassificationModel>;
}

export const ClassificationFields = ({
  categories,
  typesByCategory,
  subTypesByType,
  formApi,
}: Props) => {
  const { t } = useTranslation();
  const popupContainerRef = useRef<HTMLDivElement>(null);
  const getPopupContainer = () =>
    popupContainerRef.current ?? window.document.body;
  const categoryValue = Form.useWatch("category", formApi);
  const typeValue = Form.useWatch("type", formApi);
  const typeOptions =
    (categoryValue && typesByCategory[categoryValue]) || DEFAULT_OPTIONS;
  const subTypeOptions =
    (typeValue && subTypesByType[typeValue]) || DEFAULT_OPTIONS;
  const subTypeAvailable = !typeValue || !subTypeOptions.length;
  const { setFieldsValue } = Form.useFormInstance<ClassificationModel>();

  return (
    <>
      <Form.Item
        name="category"
        label={t(
          "bo.ordersPage.orderDetails.tabs.verification.section.documents.category"
        )}
        required
        rules={[{ required: true }]}
      >
        <Select
          placeholder={t("bo.common.field.placeholder.selectValue")}
          options={categories}
          onChange={() => formApi.resetFields(["type"])}
          data-qa-selector="classificationFieldsCategory"
          dropdownClassName="QA_ID_dropdown_category"
          getPopupContainer={getPopupContainer}
          onSelect={() => {
            setFieldsValue({ type: null, documentSubTypeKey: null });
          }}
        />
      </Form.Item>
      <Form.Item
        name="type"
        label={t(
          "bo.ordersPage.orderDetails.tabs.verification.section.documents.type"
        )}
        required
        rules={[{ required: true }]}
      >
        <Select
          disabled={!categoryValue}
          placeholder={
            categoryValue
              ? t("bo.common.field.placeholder.selectValue")
              : t(
                  "bo.orderDocuments.viewer.orderDetails.form.field.type.placeholder.unselected"
                )
          }
          options={typeOptions}
          data-qa-selector="classificationFieldsType"
          dropdownClassName="QA_ID_dropdown_type"
          getPopupContainer={getPopupContainer}
          onSelect={() => {
            setFieldsValue({ documentSubTypeKey: null });
          }}
        />
      </Form.Item>
      {subTypeAvailable ? null : (
        <Form.Item
          name="documentSubTypeKey"
          label={t(
            "bo.ordersPage.orderDetails.tabs.verification.section.documents.subType"
          )}
          required
          rules={[{ required: true }]}
        >
          <Select
            placeholder={
              typeValue
                ? t("bo.common.field.placeholder.selectValue")
                : t(
                    "bo.orderDocuments.viewer.orderDetails.form.field.subType.placeholder.unselected"
                  )
            }
            options={subTypeOptions}
            data-qa-selector="classificationFieldsSubType"
            dropdownClassName="QA_ID_dropdown_sub_type"
            getPopupContainer={getPopupContainer}
          />
        </Form.Item>
      )}
      <div ref={popupContainerRef} />
    </>
  );
};
