import { DocumentDeleteConfirmation } from "@/components/DocumentDeleteConfirmation";
import { RecoverConfirmation } from "@/components/RecoverConfirmation";
import { VerifyConfirmation } from "@/components/VerifyConfirmation";
import { ClassificationModel } from "@/types/ClassificationModel";
import { DocumentEntity } from "@/types/DocumentEntity";
import { OrderDocumentDetailsModel } from "@/types/OrderDocumentDetailsModel";
import { Button, Card } from "antd";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { ActionBy } from "./ActionBy";
import { AddressInfo } from "./AddressInfo";
import { CoOwnerInfo } from "./CoOwnerInfo";
import { CustomerInfo } from "./CustomerInfo";
import { EmploymentDetails } from "./EmploymentDetails";
import cn from "./styles.less";

interface Props
  extends Omit<
    OrderDocumentDetailsModel,
    keyof ClassificationModel | "retailCountry" | "financingType"
  > {
  document?: DocumentEntity;
  onVerify: () => Promise<unknown>;
  onRecover: () => Promise<unknown>;
  onDelete: () => Promise<unknown>;
}

export const OrderDetailCard = ({
  document,
  customer,
  billingAddress,
  documentDeliveryAddress,
  carRegistrationAddress,
  shippingAddress,
  coBorrowerAddress,
  previosHome,
  onVerify,
  onRecover,
  onDelete,
  coOwner,
  coOwnerAddress,
  ...employmentDetailsProps
}: Props) => {
  const { t } = useTranslation();
  const popupContainerRef = useRef<HTMLDivElement>(null);
  const getPopupContainer = () =>
    popupContainerRef.current ?? window.document.body;
  const { verifiedByUser, verifiedOn, deletedByUser, deletedOn, meta } =
    document ?? {};

  const isDeleted = Boolean(deletedByUser || deletedOn);

  return (
    <Card
      style={{ height: "100%" }}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
      }}
      data-qa-selector="orderDetailCard"
    >
      <div className={cn.body}>
        <strong>{t("bo.orderDocuments.viewer.orderDetails.header")}:</strong>
        <CustomerInfo customer={customer} />
        <AddressInfo
          data-qa-selector="billingAddress"
          label={t("bo.orderDocuments.viewer.orderDetails.billingAddress")}
          address={billingAddress}
        />
        <AddressInfo
          data-qa-selector="documentDeliveryAddress"
          label={t(
            "bo.orderDocuments.viewer.orderDetails.documentDeliveryAddress"
          )}
          address={documentDeliveryAddress}
        />
        <AddressInfo
          data-qa-selector="carRegistrationAddress"
          label={t(
            "bo.orderDocuments.viewer.orderDetails.carRegistrationAddress"
          )}
          address={carRegistrationAddress}
        />
        <AddressInfo
          data-qa-selector="shippingAddress"
          label={t("bo.orderDocuments.viewer.orderDetails.shippingAddress")}
          address={shippingAddress}
        />
        <AddressInfo
          data-qa-selector="previosHome"
          label={t("bo.orderDocuments.viewer.orderDetails.previousAddress")}
          address={previosHome}
        />
        <EmploymentDetails
          label={t("bo.orderDocuments.viewer.orderDetails.employmentDetails")}
          coOwnerInfo={
            coOwner && coOwnerAddress ? (
              <CoOwnerInfo coOwner={coOwner} coOwnerAddress={coOwnerAddress} />
            ) : null
          }
          {...employmentDetailsProps}
        />
        <AddressInfo
          data-qa-selector="coBorrowerAddress"
          label={t("bo.orderDocuments.viewer.orderDetails.coBorrowerAddress")}
          address={coBorrowerAddress}
        />
        <footer className={cn.footer}>
          {isDeleted ? (
            meta?.isEditAllowed && (
              <div>
                <RecoverConfirmation
                  getPopupContainer={getPopupContainer}
                  onConfirm={onRecover}
                >
                  <Button
                    data-qa-selector="recoverButton"
                    block
                    size="large"
                    type="primary"
                  >
                    {t(
                      "bo.orderDocuments.viewer.orderDetails.button.recover.label"
                    )}
                  </Button>
                </RecoverConfirmation>
              </div>
            )
          ) : (
            <div>
              {verifiedByUser || verifiedOn ? (
                <ActionBy
                  data-qa-selector="verifyAction"
                  label={t(
                    "bo.orderDocuments.viewer.orderDetails.button.verifiedBy.text"
                  )}
                  actionOn={verifiedOn}
                  actionByUser={verifiedByUser}
                />
              ) : (
                meta?.isEditAllowed && (
                  <VerifyConfirmation
                    getPopupContainer={getPopupContainer}
                    onConfirm={onVerify}
                  >
                    <Button
                      data-qa-selector="verifyButton"
                      block
                      size="large"
                      type="primary"
                    >
                      {t(
                        "bo.orderDocuments.viewer.orderDetails.button.verifiedBy.label"
                      )}
                    </Button>
                  </VerifyConfirmation>
                )
              )}
            </div>
          )}
          <div>
            {isDeleted ? (
              <ActionBy
                data-qa-selector="deleteAction"
                label={t(
                  "bo.orderDocuments.viewer.orderDetails.button.deletedBy.text"
                )}
                actionOn={deletedOn}
                actionByUser={deletedByUser}
              />
            ) : (
              meta?.isEditAllowed && (
                <DocumentDeleteConfirmation
                  getPopupContainer={getPopupContainer}
                  onConfirm={onDelete}
                >
                  <Button
                    data-qa-selector="deleteButton"
                    block
                    size="large"
                    danger
                  >
                    {t(
                      "bo.orderDocuments.viewer.orderDetails.button.deletedBy.label"
                    )}
                  </Button>
                </DocumentDeleteConfirmation>
              )
            )}
          </div>
        </footer>
      </div>
      <div ref={popupContainerRef} />
    </Card>
  );
};
