import { Nil } from "@/types";
import { ActionByUserModel } from "@/types/OrderDocumentDetailsModel";
import { formatDateTime } from "@/utils/datetime";
import { ComponentProps, ReactNode } from "react";
import { getFullName } from "../utils";

interface Props extends ComponentProps<"section"> {
  label?: ReactNode;
  actionOn: string | Nil;
  actionByUser: ActionByUserModel | Nil;
}

export const ActionBy = ({
  label,
  actionOn,
  actionByUser,
  ...props
}: Props) => {
  return (
    <section {...props}>
      <div>{label}</div>
      <div data-qa-selector="actionByUser">{getFullName(actionByUser)}</div>
      <div data-qa-selector="actionOn">{formatDateTime(actionOn)}</div>
    </section>
  );
};
