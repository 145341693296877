import { DocumentRequestEntity } from "@/types/DocumentRequestEntity";
import { formatDateTime } from "@/utils/datetime";
import { useTranslation } from "react-i18next";
import { Nil } from "../types";

interface Props {
  createdBy: DocumentRequestEntity["createdBy"] | Nil;
  createdOn: string | Nil;
}

export const CreatedBy = ({ createdBy, createdOn }: Props) => {
  const { t } = useTranslation();
  const { firstName, lastName } = createdBy ?? {};

  return (
    <>
      {t("bo.orderDocuments.documentRequests.table.by", {
        date: formatDateTime(createdOn),
        fullName: [firstName, lastName].join(" "),
        interpolation: { escapeValue: false },
      })}
    </>
  );
};
