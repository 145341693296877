import { useDocumentRequestsFeatureEnabled } from "@/hooks/useDocumentRequestsFeatureEnabled";
import { useEventListener } from "@retail/backoffice-events";
import { useEffect, useState } from "react";
import { fetchDocumentRequestsCount } from "./utils";

export interface RequestedDocumentCountProps {
  orderId: string;
  retailCountry: string;
  orderType: string;
}

export const DocumentRequestsCount = ({
  orderId,
  retailCountry,
  orderType,
}: RequestedDocumentCountProps) => {
  const [count, setCount] = useState<number>();
  const enabled = useDocumentRequestsFeatureEnabled({
    orderType,
    retailCountry,
  });

  const fetchAsync = () =>
    enabled && fetchDocumentRequestsCount({ orderId }).then(setCount);

  useEffect(() => {
    fetchAsync();
  }, [orderId, enabled]);

  useEventListener("orderDocuments.document.created", () => {
    fetchAsync();
  });
  useEventListener("orderDocuments.document.updated", () => {
    fetchAsync();
  });
  useEventListener("orderDocuments.documentRequest.created", () => {
    fetchAsync();
  });
  useEventListener("orderDocuments.documentRequest.removed", () => {
    fetchAsync();
  });
  useEventListener("orderDocuments.documentRequest.removedAll", () => {
    fetchAsync();
  });

  return count! > 0 ? <span>({count})</span> : null;
};
