import { PermissionsProvider } from "@/acl";
import { ANTD_EXTERNAL_VERSION_LESS } from "@/antdConstants";
import { createQueryClient } from "@/apiClient";
import { truncateWithEllipsis } from "@/utils/string";
import { ConfigProvider, notification } from "antd";
import { FunctionComponent, PropsWithChildren } from "react";
import { QueryClientProvider } from "react-query";

const onError = (err: unknown) => {
  const errorMessage = err instanceof Error ? err.message : String(err);

  notification.error({
    message: "Server error",
    description: truncateWithEllipsis(errorMessage, 150),
  });
};

export const QUERY_CLIENT = createQueryClient({ onError });

const RootWrapper: FunctionComponent = __LOAD_BOOTSTRAP__
  ? ({ children }) => <div className="container">{children}</div>
  : ({ children }) => <>{children}</>;

export function withQueryClientProvider<T extends {}>(
  As: FunctionComponent<T>
) {
  return (props: T) => (
    <QueryClientProvider client={QUERY_CLIENT}>
      <As {...props} />
    </QueryClientProvider>
  );
}

export const RootProvider = withQueryClientProvider<PropsWithChildren<{}>>(
  ({ children }) => {
    return (
      <PermissionsProvider>
        <ConfigProvider prefixCls={ANTD_EXTERNAL_VERSION_LESS}>
          <RootWrapper>{children}</RootWrapper>
        </ConfigProvider>
      </PermissionsProvider>
    );
  }
);

export function withRootProvider<T extends {}>(As: FunctionComponent<T>) {
  return (props: T) => (
    <RootProvider>
      <As {...props} />
    </RootProvider>
  );
}
