import { OrderCustomerModel } from "@/types/OrderDocumentDetailsModel";
import { formatDate } from "@/utils/datetime";
import { useTranslation } from "react-i18next";

interface Props {
  customer?: OrderCustomerModel;
}

export const CustomerInfo = ({ customer }: Props) => {
  const { t } = useTranslation();
  const { firstName, lastName, dateOfBirth, taxCode, type } = customer ?? {};

  return (
    <section data-qa-selector="customerInfo">
      <div data-qa-selector="customerName">
        {[firstName, lastName].filter(Boolean).join(" ")}
      </div>
      {dateOfBirth ? (
        <div data-qa-selector="customerDateOfBirth">
          {formatDate(dateOfBirth)}
        </div>
      ) : null}
      {taxCode ? (
        <div data-qa-selector="customerTaxId">
          {t("bo.orderDocuments.viewer.orderDetails.taxId", { taxCode })}
        </div>
      ) : null}
      {type ? (
        <div>
          {t(
            "bo.ordersPage.orderDetails.tabs.verification.section.documents.type"
          )}
          {": "}
          {type}
        </div>
      ) : null}
    </section>
  );
};
