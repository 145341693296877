// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JAmwKgPdOgePbCnKmm4z.JAmwKgPdOgePbCnKmm4z {\n  color: #6eb5f2;\n  font-size: 3em;\n}\n.fVCfl8feqTotVYhtzyaW {\n  font-size: 16px;\n}\n.ROPprM2Xz7mMdVB5Dx5N {\n  color: rgba(0, 0, 0, 0.45);\n}\n.NKxoDtk6pwiHFYqo4I8i.NKxoDtk6pwiHFYqo4I8i {\n  font-size: 30px;\n  color: #ff4d4f;\n}\n.TJDP_g5aZEnZwmEYcNjg.TJDP_g5aZEnZwmEYcNjg {\n  color: grey;\n}\n.x_Xo7tic_5xDqM0QlFNd {\n  margin-top: 1em;\n}\n", "",{"version":3,"sources":["webpack://./src/features/DocumentsTab/AddDocumentModal/FileUpload/styles.less"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,cAAA;AAAJ;AAIA;EACE,eAAA;AAFF;AAKA;EACE,0BAAA;AAHF;AAOE;EACE,eAAA;EACA,cAAA;AALJ;AAUE;EACE,WAAA;AARJ;AAYA;EACE,eAAA;AAVF","sourcesContent":[".uploadIcon {\n  && {\n    color: #6eb5f2;\n    font-size: 3em;\n  }\n}\n\n.uploadHint {\n  font-size: 16px;\n}\n\n.uploadAllowedFiles {\n  color: rgba(0, 0, 0, 0.45);\n}\n\n.errorIcon {\n  && {\n    font-size: 30px;\n    color: #ff4d4f;\n  }\n}\n\n.errorFileIcon {\n  && {\n    color: grey;\n  }\n}\n\n.errorFileHint {\n  margin-top: 1em;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadIcon": "JAmwKgPdOgePbCnKmm4z",
	"uploadHint": "fVCfl8feqTotVYhtzyaW",
	"uploadAllowedFiles": "ROPprM2Xz7mMdVB5Dx5N",
	"errorIcon": "NKxoDtk6pwiHFYqo4I8i",
	"errorFileIcon": "TJDP_g5aZEnZwmEYcNjg",
	"errorFileHint": "x_Xo7tic_5xDqM0QlFNd"
};
export default ___CSS_LOADER_EXPORT___;
