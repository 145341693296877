import { truncateWithEllipsis } from "@/utils/string";
import type { UploadFile } from "antd";
import fileExtension from "file-extension";
import { useMutation } from "react-query";

import { AccessDomain } from "@/acl";
import { getApiClient } from "@/apiClient";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { FileUploadCredentials } from "../types";

interface FetchUploadCredentialsProps {
  orderId: string;
  documentTypeId: string;
  files: UploadFile[];
}

const fetchUploadCredentials = ({
  orderId,
  documentTypeId,
  files,
}: FetchUploadCredentialsProps) =>
  getApiClient({
    domain: AccessDomain.BackofficeManagement,
  })
    .FetchUploadCredentials({
      documentsInfo: files.map((file) => ({
        id: orderId,
        documentTypeId: documentTypeId,
        fileExt: fileExtension(file.name),
      })),
    })
    .then((data) => (data.data?.credentials ?? []) as FileUploadCredentials[]);

const uploadFile = async ({
  file,
  credentials,
  t,
}: {
  file: UploadFile;
  credentials: FileUploadCredentials;
  t: TFunction<"translation", undefined>;
}) => {
  const formData = new FormData();
  Object.entries(credentials.formData).forEach(([name, value]) =>
    formData.append(name, value)
  );
  formData.append("Content-Type", file.type!);
  formData.append("file", file.originFileObj!);

  const fileUploadResponse = await fetch(credentials.host, {
    method: "POST",
    body: formData,
  });

  if (!fileUploadResponse.ok) {
    const text = await fileUploadResponse.text();
    throw new Error(
      t(`bo.orderDocuments.dragAndDropLabel.errorMessage`, {
        message: `${fileUploadResponse.status} ${truncateWithEllipsis(
          text,
          100
        )}`,
      })
    );
  }
};

interface UseCreateDocumentsCallbackProps {
  documentTypeId: string;
  documentSubTypeKey: string;
  files: UploadFile[];
}

export const useCreateDocuments = (orderId: string) => {
  const { t } = useTranslation();
  const { mutateAsync: fetchUploadCredentialsMutation } = useMutation(
    fetchUploadCredentials
  );

  const { mutateAsync: uploadFileMutation } = useMutation(uploadFile);

  const { mutateAsync: createDocumentMutation } = useMutation(
    getApiClient({ domain: AccessDomain.BackofficeManagement }).CreateDocument
  );

  const createDocuments = async ({
    documentTypeId,
    documentSubTypeKey,
    files,
  }: UseCreateDocumentsCallbackProps) => {
    const credentials = await fetchUploadCredentialsMutation({
      orderId,
      documentTypeId,
      files,
    });

    if (credentials.length !== files.length) {
      throw new Error("Something went wrong");
    }

    const documentsPromises = credentials.map(async (credentials, idx) => {
      const file = files[idx];

      await uploadFileMutation({
        credentials,
        file,
        t,
      });

      await createDocumentMutation({
        document: {
          attachedTo: orderId,
          documentTypeId,
          documentSubTypeKey,
          fullUrl: `${credentials.cdn}${credentials.fileName}`,
          fileName: file.name,
        },
      });
    });

    return await Promise.all(documentsPromises);
  };

  return createDocuments;
};
