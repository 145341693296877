import { Nil } from "@/types";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import parseISO from "date-fns/parseISO";

export function formatDateTime(isoDateTime: string | Nil): string {
  if (!isoDateTime) {
    return "";
  }

  const parsed = parseISO(isoDateTime);

  if (!isValid(parsed)) {
    return "";
  }

  return format(parsed, "dd/MM/yyyy HH:mm");
}

export function formatDate(isoDateTime: string | Nil): string {
  if (!isoDateTime) {
    return "";
  }

  const parsed = parseISO(isoDateTime);

  if (!isValid(parsed)) {
    return "";
  }

  return format(parsed, "dd/MM/yyyy");
}
