import { BROWSER_MAP, getParser as getBrowserParser } from "bowser";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";

import { Nil } from "@/types";
import { Loader } from "../Loader";
import cn from "./styles.less";

const isChrome = getBrowserParser(window.navigator.userAgent).isBrowser(
  BROWSER_MAP.chrome
);

interface Props {
  fullUrl: string;
  fileName: string;
  fileExtension: string | Nil;
  mimeType: string | Nil;
}

export const ObjectViewer = ({
  fullUrl,
  fileExtension,
  fileName,
  mimeType,
}: Props) => {
  const [objUrl, setObjUrl] = useState<string | null>(null);

  const requiresObjUrlForPdf = isChrome && fileExtension === "pdf";
  const dataUrl = requiresObjUrlForPdf ? objUrl : fullUrl;

  // Requires a hack to preview PDFs in Chrome as the BE doesn't send
  // the "Content-Type: application/pdf" header and it makes Chrome immediately
  // download a file
  const prefetchQuery = useQuery(
    ["object-viewer-prefetch", fullUrl],
    () =>
      fetch(fullUrl)
        .then((res) => res.blob())
        .then((blob) => blob.slice(0, blob.size, "application/pdf")),
    {
      enabled: requiresObjUrlForPdf,
    }
  );

  useEffect(() => {
    if (prefetchQuery.data) {
      const objectUrl = URL.createObjectURL(prefetchQuery.data);
      setObjUrl(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [prefetchQuery.data]);

  const [isObjectLoaded, setObjectLoaded] = useState(false);
  const objectRefCallback = useCallback((elem: HTMLObjectElement | null) => {
    if (elem) {
      setObjectLoaded(false);
    }
  }, []);

  const showLoader = requiresObjUrlForPdf
    ? !objUrl || !isObjectLoaded
    : !isObjectLoaded;

  return (
    <>
      <object
        key={dataUrl}
        ref={objectRefCallback}
        data-qa-selector="objectViewer"
        data={dataUrl ?? undefined}
        type={mimeType ?? undefined}
        className={cn.section}
        onLoad={() => setObjectLoaded(true)}
      >
        <a href={fullUrl} target="_blank" rel="noreferrer noopener">
          {fileName}
        </a>
      </object>
      {showLoader && <Loader />}
    </>
  );
};
