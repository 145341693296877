// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".he1SDniFcIGRn9rclqtH.he1SDniFcIGRn9rclqtH {\n  white-space: unset;\n  padding-left: 0;\n  text-align: left;\n}\n", "",{"version":3,"sources":["webpack://./src/components/BrowseButton/styles.less"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;AAAJ","sourcesContent":[".browseButton {\n  && {\n    white-space: unset;\n    padding-left: 0;\n    text-align: left;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"browseButton": "he1SDniFcIGRn9rclqtH"
};
export default ___CSS_LOADER_EXPORT___;
