import { DocumentViewer } from "@/components/DocumentViewer";
import { RequestDocumentModal } from "@/components/RequestDocumentModal";
import { useDocumentActions } from "@/hooks/useDocumentActions";
import { useDocumentRequestsFeatureEnabled } from "@/hooks/useDocumentRequestsFeatureEnabled";
import { useDocumentViewerProps } from "@/hooks/useDocumentViewerProps";
import { useRevokeDocumentRequest } from "@/hooks/useRevokeDocumentRequest";
import { useSearchDocumentRequests } from "@/hooks/useSearchDocumentRequests";
import { withRootProvider } from "@/providers/RootProvider";
import { ClassificationModel } from "@/types/ClassificationModel";
import { DocumentEntity } from "@/types/DocumentEntity";
import { PatchDocumentEntity } from "@/types/PatchDocumentEntity";
import { dispatchEvent, useEventListener } from "@retail/backoffice-events";
import { ORDER_TYPES } from "@retail/order-constants";
import { Button, Card, Table, notification } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentAndDocumentRequestEntity } from "./DocumentAndDocumentRequestEntity";
import { DocumentsTakenFromLink } from "./DocumentsTakenFromLink";
import { useColumns } from "./useColumns";
import { useDocumentPreview } from "./useDocumentPreview";
import { useLoadOrderVerification } from "./useLoadOrderVerification";
import { useSummaryVerificationDocuments } from "./useSummaryVerificationDocuments";

export interface SummaryDocumentsProps {
  orderId: string;
  language: string;
}

export const SummaryVerificationDocuments =
  withRootProvider<SummaryDocumentsProps>(({ orderId, language }) => {
    const [notificationApi, notificationContextHolder] =
      notification.useNotification();
    const { t } = useTranslation();
    const [localDocuments, setLocalDocuments] = useState<DocumentEntity[]>([]);
    const [shouldRefetch, setShouldRefetch] = useState(false);
    const [requestDocumentParams, setRequestDocumentParams] =
      useState<Partial<ClassificationModel> | null>(null);
    const {
      retailCountry,
      reusesVerificationFromOrderId,
      reusesVerificationFromOrderNumber,
      orderType,
      financingType,
    } = useLoadOrderVerification({ orderId });
    const isFeatureEnabledCustomerDocumentRequestBackoffice =
      useDocumentRequestsFeatureEnabled({ retailCountry, orderType });
    const revokeDocumentRequest = useRevokeDocumentRequest();

    const documents = useSummaryVerificationDocuments({
      orderId,
      retailCountry,
      financingType,
    });

    const documentRequests = useSearchDocumentRequests({
      orderId,
      enabled: isFeatureEnabledCustomerDocumentRequestBackoffice,
    });
    const documentPreview = useDocumentPreview({
      orderId,
      documents: localDocuments,
    });
    const { documentActions, documentViewerProps } = useDocumentViewerProps({
      orderId,
      notificationApi,
      onUpdateDone: () => Promise.resolve(setShouldRefetch(true)),
    });

    function onAction<Args extends Array<unknown>>(
      fn: (...args: Args) => Promise<PatchDocumentEntity>
    ) {
      return async (...args: Args) => {
        const doc = await fn(...args);

        setLocalDocuments((docs) =>
          docs.map((x) =>
            x.id === doc?.id
              ? {
                  ...x,
                  ...(doc as Omit<PatchDocumentEntity, keyof DocumentEntity>),
                }
              : x
          )
        );
      };
    }

    const onVerifyDocument = useCallback(
      onAction(documentActions.verifyDocument),
      [documentActions.verifyDocument]
    );
    const onRecoverDocument = onAction(documentActions.recoverDocument);
    const onDeleteDocument = onAction(async (id: string) => {
      const doc = await documentActions.deleteDocument(id);
      setRequestDocumentParams(
        doc
          ? {
              category: doc.documentType?.retailDocumentCategoryDTO?.name,
              type: doc.documentType?.id,
              documentSubTypeKey: doc.documentType?.retailDocumentSubType?.key,
            }
          : {}
      );
      return doc;
    });
    const onUpdateDocument = onAction(documentViewerProps.onUpdate);
    const { updateDocument, isLoading: isLoadingRotation } =
      useDocumentActions();
    const onRotate = onAction(async (id: string, rotationDegree: number) => {
      const doc = await updateDocument(id, { rotationDegree });
      setShouldRefetch(true);
      return doc;
    });

    const onCloseRequestDocument = () => setRequestDocumentParams(null);

    const onDeleteAndRefetchDocument = useCallback(
      async (id) => {
        await documentActions.deleteDocument(id);
        await documents.refetch();
      },
      [onDeleteDocument, documents.refetch]
    );

    const onDeleteAndRefetchDocumentRequest = useCallback(
      async (id) => {
        await revokeDocumentRequest({ id });
        dispatchEvent("orderDocuments.documentRequest.removed", null);
        await documentRequests.refetch();
      },
      [onDeleteDocument, documents.refetch]
    );

    const columns = useColumns({
      onVerifyDocument: onVerifyDocument,
      onDeleteDocument: onDeleteAndRefetchDocument,
      onDeleteDocumentRequest: onDeleteAndRefetchDocumentRequest,
      onPreviewDocument: documentPreview.onPreviewDocument,
    });

    async function onClose() {
      if (shouldRefetch) {
        await documents.refetch();
      }

      documentPreview.onClosePreview();
      setShouldRefetch(false);
    }

    useEffect(() => {
      setLocalDocuments(documents.documents);
    }, [documents.documents]);

    const localDocumentAndDocumentRequests = useMemo(
      () =>
        Array.from<DocumentAndDocumentRequestEntity>({ length: 0 }).concat(
          ...documentRequests.documentRequests,
          ...localDocuments
        ),
      [localDocuments, documentRequests.documentRequests]
    );

    useEventListener("orderDocuments.documentRequest.created", () => {
      documentRequests.refetch();
    });

    return (
      <Card
        size="small"
        title={
          <DocumentsTakenFromLink
            language={language}
            reusesVerificationFromOrderId={reusesVerificationFromOrderId}
            reusesVerificationFromOrderNumber={
              reusesVerificationFromOrderNumber
            }
          />
        }
        extra={
          isFeatureEnabledCustomerDocumentRequestBackoffice && (
            <Button
              type="primary"
              data-qa-selector="requestDocumentButton"
              onClick={() => setRequestDocumentParams({})}
            >
              {t("bo.orderDocuments.documentRequests.button.requestDocument")}
            </Button>
          )
        }
      >
        <Table
          size="small"
          loading={documents.isLoading || documentRequests.isLoading}
          dataSource={localDocumentAndDocumentRequests}
          columns={columns}
          rowKey="id"
          locale={{
            emptyText: t(
              "bo.ordersPage.orderDetails.tabs.verification.section.documents.notFound"
            ),
          }}
          pagination={false}
        />
        <DocumentViewer
          isOpen={documentPreview.initialId != null}
          allDocuments={localDocuments}
          previewDocuments={localDocuments}
          initialId={documentPreview.initialId}
          onClose={onClose}
          refetchDocuments={documents.refetch}
          refetchDocumentRequests={documentRequests.refetch}
          {...documentViewerProps}
          onVerify={onVerifyDocument}
          onDelete={onDeleteDocument}
          onRecover={onRecoverDocument}
          onUpdate={onUpdateDocument}
          onRotate={onRotate}
          isLoadingRotation={isLoadingRotation}
        />
        {requestDocumentParams &&
        retailCountry &&
        orderType === ORDER_TYPES.STANDARD ? (
          <RequestDocumentModal
            orderId={orderId}
            retailCountry={retailCountry}
            financingType={financingType}
            initialClassification={requestDocumentParams}
            onClose={onCloseRequestDocument}
            onDocumentsRequested={onCloseRequestDocument}
          />
        ) : null}
        {notificationContextHolder}
      </Card>
    );
  });
