export const FILE_SIZE_MAX_MB = 25;

export const MEGABYTES_TO_BYTES_MULTIPLIER = 1024 * 1024;

export const DOCUMENT_FILE_TYPES = [
  {
    ext: "jpg",
    mime: "image/jpeg",
  },
  {
    ext: "jpeg",
    mime: "image/jpeg",
  },
  {
    ext: "png",
    mime: "image/png",
  },
  {
    ext: "heic",
    mime: "image/heic",
  },
  {
    ext: "pdf",
    mime: "application/pdf",
  },
];

export const getMimeTypeByExtension = (ext: string) =>
  DOCUMENT_FILE_TYPES.find((type) => type.ext === ext)?.mime ?? null;

export const getFileNameFromUrl = (url: string) => {
  try {
    const { pathname } = new global.URL(url);

    return pathname.substring(pathname.lastIndexOf("/") + 1);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const isImageMimeType = (mimeType: string) =>
  mimeType.includes("image/");
