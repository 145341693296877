import Cookies from "js-cookie";

import { getSdk } from "@/generated/graphql";
import { AccessDomain } from "@/acl";

import { graphqlClient } from "./graphqlClient";

interface GetApiClientInput {
  domain?: AccessDomain | null;
}

export const getApiClient = ({
  domain = AccessDomain.OrderManagement,
}: GetApiClientInput = {}) =>
  getSdk(graphqlClient, (action) =>
    action({
      Authorization: `Bearer ${Cookies.get("t")}`,
      ...(domain !== null && { "access-domain": domain }),
    })
  );
