export const DOCUMENT_TOP_CATEGORY_ORDER = "order";

export const DOCUMENT_TYPES = {
  DIRECT_DEBIT_MANDATE: "DOCUMENT_FINANCE_TYPE_FINANCING_DIRECT_DEBIT_MANDATE",
  RISK_SCORECARD: "DOCUMENT_FINANCE_TYPE_RISK_SCORECARD",
  DATA_PRIVACY: "DOCUMENT_ORDER_TYPE_DATA_PRIVACY",
  KBA_APPROVAL_DECISION: "DOCUMENT_ORDER_TYPE_KBA_APPROVAL_DECISION",
  KBA_FEE_NOTICE: "DOCUMENT_ORDER_TYPE_KBA_FEE_NOTICE",
  KBA_TEMPORARY_REGISTRATION: "DOCUMENT_ORDER_TYPE_KBA_TEMPORARY_REGISTRATION",
  PROOF_OF_LICENSE_PLATE_RESERVATION:
    "DOCUMENT_ORDER_TYPE_PROOF_OF_LICENSE_PLATE_RESERVATION",
  SEPA_MANDAT: "DOCUMENT_ORDER_TYPE_SEPA_MANDAT",
  ATTORNEY: "DOCUMENT_ORDER_TYPE_ATTORNEY",
};

export const DOCUMENT_CATEGORIES = {
  FINANCE: "FINANCE",
};
