import { Nil } from "@/types";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { useEffect, useLayoutEffect, useState } from "react";

import { DocumentState } from "@/constants/DocumentState";
import { DocumentTypeKey } from "@/constants/DocumentTypeKey";
import {
  OrderFinancingType,
  UpdateDocumentClassificationMutationVariables,
} from "@/generated/graphql";
import { DocumentEntity } from "@/types/DocumentEntity";
import { OrderDocumentDetailsModel } from "@/types/OrderDocumentDetailsModel";
import { dispatchEvent } from "@retail/backoffice-events";
import { FullScreenModal } from "@retail/backoffice-ui/src/FullScreenModal";
import { Button, Card } from "antd";
import { DownloadButton } from "./DownloadButton";
import { FormCard } from "./FormCard";
import { OrderDetailCard } from "./OrderDetailCard";
import { ViewerCard } from "./ViewerCard";
import cn from "./styles.less";
import { getFileMeta } from "./utils";

const isDocumentViewable = ({ state, meta }: DocumentEntity) =>
  meta?.isContentViewAllowed &&
  [DocumentState.UPLOADED, DocumentState.DRAFT, DocumentState.DELETED].includes(
    state as DocumentState
  );

export interface DocumentViewerProps
  extends Omit<
    OrderDocumentDetailsModel,
    "category" | "type" | "documentSubTypeKey"
  > {
  allDocuments: DocumentEntity[];
  previewDocuments: DocumentEntity[];
  isOpen: boolean;
  initialId?: string;
  retailCountry: string;
  financingType: OrderFinancingType | Nil;
  isLoadingRotation: boolean;
  onClose: VoidFunction;
  onVerify: (id: string) => Promise<unknown>;
  onRecover: (id: string) => Promise<unknown>;
  onDelete: (id: string) => Promise<unknown>;
  onUpdate: (
    x: UpdateDocumentClassificationMutationVariables
  ) => Promise<unknown>;
  refetchDocuments: () => Promise<unknown>;
  refetchDocumentRequests: () => Promise<unknown>;
  onRotate: (id: string, angle: number) => Promise<void>;
}

export const DocumentViewer = ({
  allDocuments = [],
  previewDocuments,
  initialId,
  isOpen,
  retailCountry,
  financingType,
  onClose,
  onVerify,
  onRecover,
  onDelete,
  onUpdate,
  refetchDocuments,
  refetchDocumentRequests,
  onRotate,
  isLoadingRotation,
  ...orderDetailCardProps
}: DocumentViewerProps) => {
  const viewableDocuments = previewDocuments.filter(isDocumentViewable);
  const [currentId, setCurrentId] = useState<string>();
  const [isEdit, setIsEdit] = useState(false);

  const currentDoc = allDocuments.find((it) => it.id === currentId);
  const currentDocUrl = currentDoc?.fullUrl;
  const currentDocMeta = getFileMeta({
    fullUrl: currentDocUrl,
    fileName: currentDoc?.fileName,
    rotationDegree: currentDoc?.rotationDegree,
  });
  const category =
    currentDoc?.documentType?.retailDocumentCategoryDTO?.name ?? "";
  const type = currentDoc?.documentType;
  const subType = currentDoc?.documentType?.retailDocumentSubType;

  useLayoutEffect(() => setCurrentId(initialId), [initialId]);

  useEffect(
    () =>
      setIsEdit(
        type?.key === DocumentTypeKey.ATTACHED_FROM_EMAIL_REPLY
          ? true
          : !type?.id
      ),
    [type?.id]
  );

  function onArrow(diff: number) {
    const calculateNextIdx = (currentId?: string) => {
      const currentIdx = viewableDocuments.findIndex(
        (it) => it.id === currentId
      );
      const nextIdx = currentIdx + diff;
      const maxIdx = viewableDocuments.length - 1;
      const newIdx = nextIdx < 0 ? maxIdx : nextIdx > maxIdx ? 0 : nextIdx;
      return viewableDocuments[newIdx].id;
    };

    setCurrentId(calculateNextIdx);
  }

  const currentDocName = currentDocMeta.fileName || "Unknown";

  function toggleEdit() {
    setIsEdit((x) => !x);
  }

  async function handleUpdate(
    x: UpdateDocumentClassificationMutationVariables
  ) {
    await onUpdate(x);
    if (type?.key !== DocumentTypeKey.ATTACHED_FROM_EMAIL_REPLY) {
      setIsEdit(false);
    }
    refetchDocumentRequests();
    dispatchEvent("orderDocuments.document.updated", null);
  }

  function handleVerify() {
    return onVerify(currentId!);
  }
  function handleRecover() {
    return onRecover(currentId!);
  }
  function handleDelete() {
    return onDelete(currentId!);
  }
  async function handleRotation(angle: number) {
    if (!currentDoc?.id) {
      return;
    }

    await onRotate(currentDoc.id, angle);
  }

  return (
    <FullScreenModal
      bodyStyle={{
        padding: 0,
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
      zIndex={1040}
      visible={isOpen}
      footer={null}
      closable={false}
      onCancel={onClose}
    >
      <Card>
        <div className={cn.toolbar}>
          <div
            data-qa-selector="classificationInfo"
            className={cn.documentName}
          >
            <span>
              {[category, type?.name, subType?.name]
                .filter(Boolean)
                .join(" , ")}
            </span>
            {currentDoc?.meta?.isEditAllowed &&
              type?.key !== DocumentTypeKey.ATTACHED_FROM_EMAIL_REPLY && (
                <Button
                  data-qa-selector="editDocumentClassification"
                  type={isEdit ? "primary" : "default"}
                  size="large"
                  className={cn.editButton}
                  icon={<EditOutlined />}
                  onClick={toggleEdit}
                />
              )}
          </div>
          <div className={cn.toolbarButtons}>
            <DownloadButton
              currentDocUrl={currentDocUrl}
              fileName={currentDocMeta?.fileName}
            />
            <Button
              data-qa-selector="closeButton"
              size="large"
              icon={<CloseOutlined />}
              onClick={onClose}
            />
          </div>
        </div>
      </Card>
      <div className={cn.body} data-qa-selector={retailCountry}>
        {currentDocUrl && (
          <ViewerCard
            currentDocUrl={currentDocUrl}
            currentDocName={currentDocName}
            currentDocMeta={currentDocMeta}
            viewableDocuments={viewableDocuments}
            isLoadingRotation={isLoadingRotation}
            onArrow={onArrow}
            onRotate={handleRotation}
          />
        )}
        {isEdit ? (
          <FormCard
            category={category}
            type={type?.id}
            documentSubTypeKey={subType?.key}
            retailCountry={retailCountry}
            financingType={financingType}
            documentId={currentDoc?.id}
            document={currentDoc}
            onSubmit={handleUpdate}
            onCancel={toggleEdit}
          />
        ) : (
          <OrderDetailCard
            {...orderDetailCardProps}
            document={currentDoc}
            onVerify={handleVerify}
            onRecover={handleRecover}
            onDelete={handleDelete}
          />
        )}
      </div>
    </FullScreenModal>
  );
};
