import { ReactZoomPanPinchContext } from "react-zoom-pan-pinch";

export function calculateScaleToFit({
  wrapperComponent,
  contentComponent,
  transformState,
}: ReactZoomPanPinchContext) {
  if (wrapperComponent && contentComponent) {
    const nodeRect = contentComponent.getBoundingClientRect();

    const nodeWidth = nodeRect.width / transformState.scale;
    const nodeHeight = nodeRect.height / transformState.scale;

    const scaleX = wrapperComponent.offsetWidth / nodeWidth;
    const scaleY = wrapperComponent.offsetHeight / nodeHeight;

    return Math.min(scaleX, scaleY);
  }
}
