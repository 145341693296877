import { BrowseButton } from "@/components/BrowseButton";
import { CellVerification } from "@/components/CellVerification";
import { DocumentTypeKey } from "@/constants/DocumentTypeKey";
import { CellActions } from "@/features/DocumentsTab/DocumentsList/CellActions";
import { ListType, Nil } from "@/features/DocumentsTab/types";
import { DocumentEntity } from "@/types/DocumentEntity";
import { formatDateAndUser } from "@/utils/formatters";
import { MinusOutlined } from "@ant-design/icons";
import { TableCell } from "@retail/backoffice-ui/src/PaginatedTable/TableCell";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

interface DocumentsListProps {
  listType: ListType;
  documents: DocumentEntity[] | Nil;
  isLoading: boolean;
  onPreviewDocument: (id: string) => void;
  onDeleteDocument: (id: string) => Promise<unknown>;
  onRecoverDocument: (id: string) => Promise<unknown>;
  onVerifyDocument: (id: string) => Promise<unknown>;
}

export const DocumentsList = (props: DocumentsListProps) => {
  const { listType } = props;
  const { t } = useTranslation();
  const createQaIdGetter = (qaId: string) => ({
    onCell: () =>
      ({
        "data-qa-selector": qaId,
      } as any),
  });

  const columns: ColumnsType<DocumentEntity> = [
    {
      key: "idx",
      title: "#",
      width: "2%",
      ...createQaIdGetter("itemIndex"),
      render: (_, __, idx) => idx + 1,
    },
    {
      key: "category",
      title: t(
        "bo.ordersPage.orderDetails.tabs.verification.section.documents.category"
      ),
      ...createQaIdGetter("itemCategory"),
      render: (_, doc) =>
        t(
          `bo.orderDocuments.documentCategory.${doc.documentType?.retailDocumentCategoryDTO?.name}`
        ),
    },
    {
      dataIndex: ["documentType", "key"],
      title: t(
        "bo.ordersPage.orderDetails.tabs.verification.section.documents.type"
      ),
      ...createQaIdGetter("itemType"),
      render: (value) => (
        <TableCell>
          {value ? t(`bo.orderDocuments.documentType.${value}`) : null}
        </TableCell>
      ),
    },
    {
      dataIndex: ["documentType", "retailDocumentSubType", "key"],
      title: t(
        "bo.ordersPage.orderDetails.tabs.verification.section.documents.subType"
      ),
      ...createQaIdGetter("subItemType"),
      render: (value) => (
        <TableCell>
          {value ? t(`bo.orderDocuments.documentSubType.${value}`) : null}
        </TableCell>
      ),
    },
    {
      key: "document",
      title: t("bo.orderDocuments.document"),
      ...createQaIdGetter("itemBrowse"),
      render: (_, doc) => (
        <BrowseButton
          document={doc}
          onPreviewDocument={props.onPreviewDocument}
        >
          {t("bo.orderDocuments.browse")}
        </BrowseButton>
      ),
    },
    {
      key: "upload",
      title: t("bo.orderDocuments.uploadInfo"),
      ...createQaIdGetter("itemCreatedOn"),
      render: (_, doc) => formatDateAndUser(doc.createdOn, doc.createdByUser),
    },
    {
      key: "verification",
      title: t("bo.orderDocuments.verification"),
      ...createQaIdGetter("itemVerification"),
      render: (_, doc) => (
        <CellVerification
          doc={doc}
          onVerify={() => props.onVerifyDocument(doc.id)}
        />
      ),
    },
    ...(listType === "deleted"
      ? [
          {
            key: "deleted",
            title: t("bo.orderDocuments.deletedInfo"),
            ...createQaIdGetter("itemDeleted"),
            render: (
              _: unknown,
              { deletedOn, deletedByUser }: DocumentEntity
            ) =>
              deletedOn ? (
                formatDateAndUser(deletedOn, deletedByUser, "the system")
              ) : (
                <MinusOutlined />
              ),
          },
        ]
      : []),
    {
      key: "actions",
      title: t("bo.orderDocuments.actions"),
      align: "center",
      width: "6%",
      ...createQaIdGetter("itemActions"),
      render: (_, doc) =>
        doc.documentType?.key === DocumentTypeKey.ATTACHED_FROM_EMAIL_REPLY ? (
          <MinusOutlined />
        ) : (
          <CellActions
            doc={doc}
            onDelete={() => props.onDeleteDocument(doc.id)}
            onRecover={() => props.onRecoverDocument(doc.id)}
          />
        ),
    },
  ];

  return (
    <Table
      loading={props.isLoading}
      dataSource={props.documents!}
      columns={columns}
      rowKey="id"
      size="small"
      locale={{
        emptyText: t(
          "bo.ordersPage.orderDetails.tabs.verification.section.documents.notFound"
        ),
      }}
      pagination={false}
      onRow={(it) =>
        ({
          "data-qa-selector": "documentRow",
          "data-qa-selector-value": it.documentType?.key ?? "UNKNOWN",
        } as HTMLAttributes<unknown>)
      }
    />
  );
};
