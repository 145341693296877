import { BrowseButton } from "@/components/BrowseButton";
import { CellVerification } from "@/components/CellVerification";
import { DocumentDeleteConfirmation } from "@/components/DocumentDeleteConfirmation";
import { DocumentRequestDeleteConfirmation } from "@/components/DocumentRequestDeleteConfirmation";
import { DocumentTypeKey } from "@/constants/DocumentTypeKey";
import { DeleteFilled } from "@ant-design/icons";
import { TableCell } from "@retail/backoffice-ui/src/PaginatedTable/TableCell";
import { Button, TableColumnsType } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DocumentAndDocumentRequestEntity } from "./DocumentAndDocumentRequestEntity";
import { RequestedBy } from "./RequestedBy";

interface Props {
  onVerifyDocument: (id: string) => Promise<unknown>;
  onPreviewDocument: (id: string) => void;
  onDeleteDocument: (id: string) => Promise<unknown>;
  onDeleteDocumentRequest: (id: string) => Promise<unknown>;
}

export function useColumns({
  onVerifyDocument,
  onPreviewDocument,
  onDeleteDocument,
  onDeleteDocumentRequest,
}: Props) {
  const { t } = useTranslation();

  return useMemo(
    (): TableColumnsType<DocumentAndDocumentRequestEntity> => [
      {
        key: "id",
        title: <strong>#</strong>,
        width: "2%",
        render: (_, __, idx) => idx + 1,
      },
      {
        dataIndex: ["documentType", "retailDocumentCategoryDTO", "name"],
        title: (
          <strong>
            {t(
              "bo.ordersPage.orderDetails.tabs.verification.section.documents.category"
            )}
          </strong>
        ),
        render: (value) => (
          <TableCell data-qa-selector="category">
            {t(`bo.orderDocuments.documentCategory.${value}`)}
          </TableCell>
        ),
      },
      {
        dataIndex: ["documentType", "key"],
        title: (
          <strong>
            {t(
              "bo.ordersPage.orderDetails.tabs.verification.section.documents.type"
            )}
          </strong>
        ),
        render: (value, doc) =>
          doc.__typename === "SearchRetailDocumentProjection" ? (
            <TableCell>
              {doc.meta.isContentViewAllowed ? (
                <BrowseButton
                  data-qa-selector="type"
                  document={doc}
                  fallback={value}
                  onPreviewDocument={onPreviewDocument}
                >
                  {value ? t(`bo.orderDocuments.documentType.${value}`) : null}
                </BrowseButton>
              ) : null}
            </TableCell>
          ) : (
            <TableCell data-qa-selector="type">
              {value ? t(`bo.orderDocuments.documentType.${value}`) : null}
            </TableCell>
          ),
      },
      {
        dataIndex: ["documentType", "retailDocumentSubType", "key"],
        title: (
          <strong>
            {t(
              "bo.ordersPage.orderDetails.tabs.verification.section.documents.subType"
            )}
          </strong>
        ),
        render: (value) => (
          <TableCell data-qa-selector="subType">
            {value ? t(`bo.orderDocuments.documentSubType.${value}`) : null}
          </TableCell>
        ),
      },
      {
        dataIndex: "id",
        title: (
          <strong>
            {t(
              "bo.ordersPage.orderDetails.tabs.verification.section.documents.status"
            )}
          </strong>
        ),
        render: (_, doc) =>
          doc.__typename === "SearchRetailDocumentProjection" ? (
            <>
              <CellVerification
                doc={doc}
                onVerify={() => onVerifyDocument(doc.id)}
              />
            </>
          ) : (
            <TableCell data-qa-selector="status">
              <RequestedBy
                createdBy={doc.createdBy}
                createdOn={doc.createdOn}
              />
            </TableCell>
          ),
      },
      {
        dataIndex: "id",
        title: (
          <strong>
            {t(
              "bo.ordersPage.orderDetails.tabs.verification.section.documents.action"
            )}
          </strong>
        ),
        align: "center",
        width: "6%",
        render: (id, doc) =>
          doc.__typename === "SearchRetailDocumentProjection" ? (
            <TableCell>
              {doc.meta.isEditAllowed &&
              doc.documentType?.key !==
                DocumentTypeKey.ATTACHED_FROM_EMAIL_REPLY ? (
                <DocumentDeleteConfirmation
                  onConfirm={() => onDeleteDocument(id)}
                >
                  <Button
                    type="primary"
                    danger
                    data-qa-selector="documentDeleteButton"
                    icon={<DeleteFilled />}
                  />
                </DocumentDeleteConfirmation>
              ) : null}
            </TableCell>
          ) : (
            <DocumentRequestDeleteConfirmation
              onConfirm={() => onDeleteDocumentRequest(id)}
              data-qa-selector="documentRequestDeleteButton"
            />
          ),
      },
    ],
    [
      onVerifyDocument,
      onDeleteDocument,
      onPreviewDocument,
      onDeleteDocumentRequest,
      t,
    ]
  );
}
