import { getApiClient } from "@/apiClient";
import { MAX_PAGE_SIZE } from "@/constants/page";
import { DocumentsByListType } from "@/features/DocumentsTab/types";
import { LoadDocumentsQuery } from "@/generated/graphql";
import { useDocumentsPermissionsChecker } from "@/hooks/useDocumentsPermissionsChecker";
import { Filters, Sorts, createFilterPayload } from "@retail/gql-utils";
import { useQuery } from "react-query";

import { DocumentState } from "@/constants/DocumentState";
import { DocumentEntity } from "@/types/DocumentEntity";

export const useDocumentsData = (orderId: string) => {
  const { data: orderData } = useQuery(
    ["useDocumentsData.LoadBasicOrderData", orderId],
    () => getApiClient().LoadBasicOrderData({ orderId })
  );

  const permissionsChecker = useDocumentsPermissionsChecker({
    retailCountry: orderData?.order?.retailCountry,
    financingType: orderData?.orderFinancing?.financingType,
  });

  const prepareDocuments = ({ searchDocuments }: LoadDocumentsQuery) => {
    const docs = (searchDocuments?.entities ?? []) as DocumentEntity[];
    const documentsByState = docs.reduce<DocumentsByListType>(
      (byState, doc) => {
        const list = {
          [DocumentState.UPLOADED]: byState.uploaded,
          [DocumentState.DRAFT]: byState.draft,
          [DocumentState.DELETED]: byState.deleted,
          [DocumentState.HARD_DELETED]: byState.deleted,
        }[doc.state];

        if (list) {
          list.push({
            ...doc,
            meta: permissionsChecker(doc),
          });
        }

        return byState;
      },
      { uploaded: [], draft: [], deleted: [] }
    );

    const allDocuments = Array.from<DocumentEntity>({ length: 0 }).concat(
      ...Object.values(documentsByState)
    );

    return { documentsByState, allDocuments };
  };

  const documentsQuery = useQuery(
    ["useDocumentsData.LoadDocuments", orderId],
    () =>
      getApiClient().LoadDocuments({
        search: createFilterPayload({
          filter: Filters.equal("attachedTo", orderId),
          sorts: [Sorts.desk("createdOn")],
          pageSize: MAX_PAGE_SIZE,
        }),
      }),
    { select: prepareDocuments }
  );

  return {
    order: orderData?.order,
    orderFinancing: orderData?.orderFinancing,
    allDocuments: documentsQuery.data?.allDocuments,
    documents: documentsQuery.data?.documentsByState,
    isDocumentsLoading: documentsQuery.isFetching,
    refetch: documentsQuery.refetch,
  };
};
