import { Nil } from "@/types";
import { formatDateTime } from "@/utils/datetime";

export const formatDateAndUser = (
  date: string,
  user: Partial<Record<"firstName" | "lastName" | "email", string | Nil>> | Nil,
  noUserText: string = ""
) => {
  const userName = user ? [user.firstName, user.lastName].join(" ").trim() : "";
  const actionMadeBy = userName || user?.email || noUserText;
  return (
    <>
      {formatDateTime(date)}
      {actionMadeBy && (
        <>
          {" "}
          by <br />
          {actionMadeBy}
        </>
      )}
    </>
  );
};
