/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  FullscreenOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { CSSProperties, useCallback, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { Loader } from "../Loader";
import cn from "./styles.less";
import { calculateScaleToFit } from "./utils";

interface Props {
  fullUrl: string;
  fileName: string;
  rotation: number;
  isLoadingRotation?: boolean;
  onRotate: (newAngle: number) => void;
}

export const ImageViewer = ({
  fullUrl,
  fileName,
  rotation,
  isLoadingRotation,
  onRotate,
}: Props) => {
  const [isImgLoading, setImgLoading] = useState(true);
  const { t } = useTranslation();

  const setImageUrl = useCallback(
    (elem: HTMLImageElement | null) => {
      if (elem && fullUrl) {
        /*
         Fix for Safari. We should add `load` event listener first
          and then set `src` attribute. Otherwise event handler might not work.
        */
        elem.src = fullUrl;
      }
    },
    [fullUrl]
  );

  const style: CSSProperties = isImgLoading
    ? { visibility: "hidden" }
    : { opacity: 1 };

  function rotate(angle: number) {
    return () => {
      if (isLoadingRotation) {
        return;
      }

      onRotate((rotation + angle) % 360);
    };
  }

  return (
    <>
      <TransformWrapper minScale={0.01} centerZoomedOut centerOnInit>
        {({ zoomIn, zoomOut, instance, centerView }) => (
          <>
            <TransformComponent wrapperClass={cn.zoomWrapper}>
              <img
                data-qa-selector="imageViewer"
                ref={setImageUrl}
                className={cn.image}
                alt={fileName ?? t("bo.orderDocuments.noAlt")}
                style={{
                  ...style,
                  transform: `rotate(${rotation}deg)`,
                }}
                onLoad={() => {
                  setImgLoading(false);
                  centerView(calculateScaleToFit(instance), 0);
                }}
              />
            </TransformComponent>
            {!isImgLoading && (
              <div data-qa-selector="viewerActions" className={cn.zoomToolbar}>
                <div
                  data-qa-selector="rotateLeft"
                  className={`${cn.zoomToolbarItem} ${
                    isLoadingRotation ? cn.disabled : ""
                  }`}
                  onClick={rotate(-90)}
                >
                  {isLoadingRotation ? (
                    <Spin className={cn.spinner} />
                  ) : (
                    <RotateLeftOutlined />
                  )}
                </div>
                <div
                  data-qa-selector="rotateRight"
                  className={`${cn.zoomToolbarItem} ${
                    isLoadingRotation ? cn.disabled : ""
                  }`}
                  onClick={rotate(90)}
                >
                  {isLoadingRotation ? (
                    <Spin className={cn.spinner} />
                  ) : (
                    <RotateRightOutlined />
                  )}
                </div>
                <div
                  data-qa-selector="zoomOut"
                  className={cn.zoomToolbarItem}
                  onClick={() => zoomOut()}
                >
                  <ZoomOutOutlined />
                </div>
                <div
                  data-qa-selector="zoomIn"
                  className={cn.zoomToolbarItem}
                  onClick={() => zoomIn()}
                >
                  <ZoomInOutlined />
                </div>
                <div
                  data-qa-selector="fullscreen"
                  className={cn.zoomToolbarItem}
                  onClick={() => {
                    centerView(calculateScaleToFit(instance), 0);
                  }}
                >
                  <FullscreenOutlined />
                </div>
              </div>
            )}
          </>
        )}
      </TransformWrapper>
      {isImgLoading && <Loader />}
    </>
  );
};
