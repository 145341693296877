import { TFunction } from "i18next";

import { CUSTOMER_TYPES } from "@retail/order-constants";
import { FINANCING_TYPES } from "@retail/order-financing/src/constants";

import { DocumentTemplatesContext } from "./types";
import { DocumentTemplateItem } from "../types";

export const getDeTemplates = (
  ctx: DocumentTemplatesContext,
  t: TFunction
): DocumentTemplateItem[] => {
  if (
    ctx.primaryCustomerType === CUSTOMER_TYPES.COMPANY &&
    ctx.financingFlowType === FINANCING_TYPES.EXTERNAL
  ) {
    return [
      {
        title: t(
          "bo.orderDocuments.documentTemplates.DE.declarationOfBeneficialOwner"
        ),
        fullUrl:
          "https://content.autohero.com/static/car_images/Erklaerung-zum-wirtschaftlich-Berechtigten-des-Vertragspartners-nach-dem-Geldwaeschegesetz.pdf",
      },
    ];
  }

  return [];
};
