import { getApiClient } from "@/apiClient";
import { useQuery } from "react-query";

interface Props {
  orderId: string;
}

export function useLoadOrderVerification({ orderId }: Props) {
  const { data } = useQuery(["LoadOrderVerification", orderId], () =>
    getApiClient().LoadOrderVerification({ orderId })
  );
  const verification = data?.order?.customer?.verification;

  return {
    retailCountry: data?.order?.retailCountry,
    orderType: data?.order?.type,
    financingType: data?.orderFinancing?.financingType,
    reusesVerificationFromOrderId: verification?.reusesVerificationFromOrderId,
    reusesVerificationFromOrderNumber:
      verification?.reusesVerificationFromOrderNumber,
  };
}
