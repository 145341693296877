import { DocumentRequestEntity } from "@/types/DocumentRequestEntity";
import { formatDate } from "@/utils/datetime";
import { useTranslation } from "react-i18next";

interface Props {
  createdBy: DocumentRequestEntity["createdBy"];
  createdOn: DocumentRequestEntity["createdOn"];
}

export const RequestedBy = ({ createdBy, createdOn }: Props) => {
  const { t } = useTranslation();
  const { firstName, lastName } = createdBy ?? {};

  return (
    <strong>
      {t("bo.orderDocuments.requestDocuments.table.requestedBy", {
        fullName: [firstName, lastName].join(" "),
        interpolation: { escapeValue: false },
      })}
      <br />
      {formatDate(createdOn)}
    </strong>
  );
};
