// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lDkfBHOQ4hCuvYeRJ5gK {\n  position: relative;\n  display: grid;\n  height: 100%;\n  width: 100%;\n  grid-template-columns: 2fr 1fr;\n  gap: 16px;\n}\n.ReactModal__Body--open {\n  overflow: hidden;\n}\n.e_oz86xTRfQOEAoMrglj {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  gap: 16px;\n}\n.YS2_L8h6cSmjGpMypqJ4 {\n  flex-grow: 1;\n  text-align: center;\n}\n.BXIyagTAfvp9_zYpIulO {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n}\n.NYRqsiYHbm1Aorl_ng3s {\n  margin-left: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DocumentViewer/styles.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;EACA,8BAAA;EACA,SAAA;AACF;AAEA;EAEI,gBAAA;AADJ;AAKA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,SAAA;AAHF;AAMA;EACE,YAAA;EACA,kBAAA;AAJF;AAOA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AALF;AAQA;EACE,iBAAA;AANF","sourcesContent":[".body {\n  position: relative;\n  display: grid;\n  height: 100%;\n  width: 100%;\n  grid-template-columns: 2fr 1fr;\n  gap: 16px;\n}\n\n:global {\n  .ReactModal__Body--open {\n    overflow: hidden;\n  }\n}\n\n.toolbar {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  gap: 16px;\n}\n\n.documentName {\n  flex-grow: 1;\n  text-align: center;\n}\n\n.toolbarButtons {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n}\n\n.editButton {\n  margin-left: 16px;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "lDkfBHOQ4hCuvYeRJ5gK",
	"toolbar": "e_oz86xTRfQOEAoMrglj",
	"documentName": "YS2_L8h6cSmjGpMypqJ4",
	"toolbarButtons": "BXIyagTAfvp9_zYpIulO",
	"editButton": "NYRqsiYHbm1Aorl_ng3s"
};
export default ___CSS_LOADER_EXPORT___;
