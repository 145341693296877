import { AccessDomain } from "@/acl";
import { getApiClient } from "@/apiClient";
import { useMutation } from "react-query";

export const useRevokeDocumentRequest = () => {
  const { mutateAsync } = useMutation(
    getApiClient({ domain: AccessDomain.BackofficeManagement })
      .RevokeDocumentRequest
  );

  return mutateAsync;
};
