// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c3tOXH_5CfaD5UBUM2oA {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  align-items: center;\n  justify-content: center;\n  gap: 16px;\n  min-height: 66px;\n}\n.QDDKaMaeZi7qM5UI0A96 {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n}\n.AVwizoyJONkLwaklPXKF {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  text-align: center;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DocumentViewer/OrderDetailCard/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,gBAAA;AACF;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;AAAF;AAGA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,kBAAA;EACA,uBAAA;AADF","sourcesContent":[".footer {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  align-items: center;\n  justify-content: center;\n  gap: 16px;\n  min-height: 66px;\n}\n\n.card {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n}\n\n.body {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  text-align: center;\n  justify-content: center;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "c3tOXH_5CfaD5UBUM2oA",
	"card": "QDDKaMaeZi7qM5UI0A96",
	"body": "AVwizoyJONkLwaklPXKF"
};
export default ___CSS_LOADER_EXPORT___;
