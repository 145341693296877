import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { NAMESPACE } from "./constants";
import { getLocale } from "./utils";

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  // It will load resources from S3 Bucket using the XMLHttpRequest or the fetch API.
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    // debug: true,
    // Loaded namespace on init.
    // Namespaces are the translation files that is related to phraseapp project
    // For more details read https://www.i18next.com/principles/namespaces
    ns: [NAMESPACE],
    // Defines default namespace
    // Default namespace allows us to use translation key without specifying its namespace
    // Example:
    // i18next.t('myKey'); -  key in "common" namespace (defined default)
    // i18next.t('ns2:myKey'); - key in "ns2" namespace
    // defaultNS: 'retail-backoffice',
    // language to use if translations in user language are not available.
    // Setting it explicitly to false will not trigger to load the fallbackLng at all.
    fallbackLng: process.env.DEFAULT_LANGUAGE,
    // Language codes to lookup, given set language is 'en-US': 'all' --> ['en-US', 'en', 'dev'], 'currentOnly' --> 'en-US', 'languageOnly' --> 'en'
    load: "currentOnly",
    interpolation: { prefix: "{", suffix: "}" },
    lowerCaseLng: true,
    // HttpApi options
    backend: {
      crossDomain: true,
      // URL to translation files (S3 Bucket)
      loadPath: (languages: string, namespaces: string[]) =>
        `${process.env.TRANSLATIONS_URL}/${namespaces[0]}/${getLocale(
          languages[0]
        )}.json`,
    },
    // LanguageDetector options
    detection: {
      order: ["path", "localStorage"],
      lookupFromPathIndex: 0,
      lookupLocalStorage: process.env.LANGUAGE_KEY,
      caches: ["localStorage"],
    },
    react: {
      useSuspense: false,
    },
  });

export { i18n };
