import { getApiClient } from "@/apiClient";
import { DocumentRequestState } from "@/constants/DocumentRequestState";
import { MAX_PAGE_SIZE } from "@/constants/page";
import { DocumentRequestEntity } from "@/types/DocumentRequestEntity";
import {
  FilterType,
  Filters,
  Sorts,
  createFilter,
  createFilterPayload,
} from "@retail/gql-utils";
import { useQuery } from "react-query";

const DEFAULT_VALUE: DocumentRequestEntity[] = [];

interface Props {
  orderId: string;
  enabled: boolean;
}

export const useSearchDocumentRequests = ({ orderId, enabled }: Props) => {
  const { data, isLoading, refetch } = useQuery(
    ["useSearchDocumentRequests", orderId],
    () =>
      getApiClient().SearchDocumentRequests({
        search: createFilterPayload({
          filter: createFilter("", FilterType.AND, [
            Filters.equal("state", DocumentRequestState.REQUESTED),
            Filters.equal("attachedTo", orderId),
          ]),
          sorts: [Sorts.desk("createdOn")],
          pageSize: MAX_PAGE_SIZE,
        }),
      }),
    { enabled }
  );

  return {
    documentRequests:
      (data?.searchDocumentRequests?.entities as DocumentRequestEntity[]) ??
      DEFAULT_VALUE,
    isLoading,
    refetch,
  };
};
