// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iYBMU2_M69l5MFX1nF4w.iYBMU2_M69l5MFX1nF4w,\n.iYBMU2_M69l5MFX1nF4w.iYBMU2_M69l5MFX1nF4w:hover {\n  color: white;\n  background-color: #9954bb;\n  padding: 0 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/features/DocumentsTab/DocumentsList/styles.less"],"names":[],"mappings":"AACE;;EAEE,YAAA;EACA,yBAAA;EACA,cAAA;AAAJ","sourcesContent":[".recoverButton {\n  &&,\n  &&:hover {\n    color: white;\n    background-color: #9954bb;\n    padding: 0 8px;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recoverButton": "iYBMU2_M69l5MFX1nF4w"
};
export default ___CSS_LOADER_EXPORT___;
