import { DocumentsTab } from "@/features/DocumentsTab";
import { useDevAuthToken } from "@retail/dev-auth";

import "./i18n";
import { RootProvider } from "./providers/RootProvider";

const getOrderIdFromSearch = () =>
  new URLSearchParams(location.search).get("orderId");
export interface RootProps {
  orderId: string;
  isModalInitiallyOpen: boolean;
}

export const Root = (props: RootProps) => {
  if (__IS_DEV_AUTH__) {
    // We call hook depending on build-time constant condition
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDevAuthToken();
  }

  return (
    <RootProvider>
      <DocumentsTab
        orderId={props.orderId ?? getOrderIdFromSearch()}
        isModalInitiallyOpen={props.isModalInitiallyOpen}
      />
    </RootProvider>
  );
};

export default Root;
