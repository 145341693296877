import { OrderDocumentDetailsModel } from "@/types/OrderDocumentDetailsModel";

interface Props {
  borrowerName: OrderDocumentDetailsModel["coBorrowerName"];
}

export const BorrowerName = ({ borrowerName }: Props) => {
  if (!borrowerName) {
    return null;
  }

  const { firstName, lastName } = borrowerName;

  return (
    <div data-qa-selector="borrowerName">
      {firstName} {lastName}
    </div>
  );
};
