import { getApiClient } from "@/apiClient";
import { DocumentRequestState } from "@/constants/DocumentRequestState";
import { MAX_PAGE_SIZE } from "@/constants/page";
import { SearchDocumentRequestsCountQuery } from "@/generated/graphql";
import { QUERY_CLIENT } from "@/providers/RootProvider";
import {
  FilterType,
  Filters,
  createFilter,
  createFilterPayload,
} from "@retail/gql-utils";

const selectData = (x?: SearchDocumentRequestsCountQuery) =>
  x?.searchDocumentRequests?.totalEntityCount;

interface Props {
  orderId?: string;
}

export const fetchDocumentRequestsCount = ({ orderId }: Props) =>
  QUERY_CLIENT.fetchQuery(["SearchDocumentRequestsCount", orderId], () =>
    getApiClient().SearchDocumentRequestsCount({
      search: createFilterPayload({
        filter: createFilter("", FilterType.AND, [
          Filters.equal("state", DocumentRequestState.REQUESTED),
          Filters.equal("attachedTo", orderId),
        ]),
        pageSize: MAX_PAGE_SIZE,
      }),
    })
  ).then(selectData);
