import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RequestDocumentReason } from "./constants";

export const useRequestDocumentReasonOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      Object.keys(RequestDocumentReason).map((value) => {
        const label = t(
          `bo.orderDocuments.requestDocuments.form.field.reason.option.${value}`
        );

        return {
          label,
          value: label,
        };
      }),
    [t]
  );
};
