import { QueryClient } from "react-query";

interface CreateQueryClientOpts {
  onError: (err: unknown) => void;
}

export const createQueryClient = ({ onError }: CreateQueryClientOpts) =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        onError,
      },
      mutations: {
        onError,
      },
    },
  });
