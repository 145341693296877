import { ComponentProps, PropsWithChildren, ReactNode } from "react";

interface Props extends ComponentProps<"div"> {
  label: ReactNode;
}

export const LabeledRow = ({
  label,
  children,
  ...props
}: PropsWithChildren<Props>) => {
  return children ? (
    <section {...props}>
      <span>{label}: </span>
      <span data-qa-selector="labeledRowValue">{children}</span>
    </section>
  ) : null;
};
