// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pp_Ts6kGtdpblrOMI9uP {\n  position: relative;\n  flex: 1 1;\n  align-items: center;\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DocumentViewer/ViewerCard/styles.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,mBAAA;EACA,YAAA;AACF","sourcesContent":[".section {\n  position: relative;\n  flex: 1 1;\n  align-items: center;\n  height: 100%;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "pp_Ts6kGtdpblrOMI9uP"
};
export default ___CSS_LOADER_EXPORT___;
