import { DocumentState } from "@/constants/DocumentState";
import { DocumentTypeKey } from "@/constants/DocumentTypeKey";
import { DocumentEntity } from "@/types/DocumentEntity";
import { formatDateAndUser } from "@/utils/formatters";
import { CheckOutlined, MinusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { VerifyConfirmation } from "../VerifyConfirmation";
import { SignatureProvider } from "./constants";
import cn from "./styles.less";
import youSignLogo from "./yousign-icon-s.png";

interface CellVerificationProps {
  doc: DocumentEntity;
  onVerify: () => Promise<unknown>;
}

export const CellVerification = ({ doc, onVerify }: CellVerificationProps) => {
  const { t } = useTranslation();

  if (doc.documentType?.key === DocumentTypeKey.ATTACHED_FROM_EMAIL_REPLY) {
    return <MinusOutlined />;
  }

  if (doc.signed) {
    return (
      <>
        {doc.signatureProvider === SignatureProvider.YOU_SIGN && (
          <img
            src={youSignLogo}
            alt="yousign"
            title={t("bo.orderDocuments.signedWithYouSign")}
            width="20px"
            height="20px"
          />
        )}
        {formatDateAndUser(doc.signedOn || "", doc.signedByUser)}
      </>
    );
  }

  if (doc.verified) {
    return formatDateAndUser(doc.verifiedOn || "", doc.verifiedByUser);
  }

  if (doc.state === DocumentState.UPLOADED && doc.meta.isEditAllowed) {
    return (
      <VerifyConfirmation onConfirm={onVerify}>
        <Button
          type="default"
          size="small"
          data-qa-selector="markVerifiedButton"
          className={cn.verifyButton}
        >
          <CheckOutlined />
          {t(
            "bo.ordersPage.orderDetails.tabs.verification.section.documents.markAsVerified"
          )}
        </Button>
      </VerifyConfirmation>
    );
  }

  return <MinusOutlined />;
};
