import { OrderFinancingType } from "@/generated/graphql";
import { Nil } from "@/types";
import { Button, Form, Input, Modal, Select, Space, Spin } from "antd";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ClassificationFields } from "@/components/ClassificationFields";
import { useCategoriesData } from "@/hooks/useCategoriesData";
import { ClassificationModel } from "@/types/ClassificationModel";
import { dispatchEvent } from "@retail/backoffice-events";
import { RequestDocumentReason } from "./constants";
import { useCreateDocumentRequest } from "./useCreateDocumentRequest";
import { useRequestDocumentReasonOptions } from "./useRequestDocumentReasonOptions";

const { TextArea } = Input;

export interface FormValues extends ClassificationModel {
  requestReason?: string;
  comment?: string;
  internalComment?: string;
}

interface Props {
  orderId: string;
  retailCountry: string;
  financingType: OrderFinancingType | Nil;
  initialClassification?: Partial<ClassificationModel>;
  onClose: VoidFunction;
  onDocumentsRequested?: VoidFunction;
}

export const RequestDocumentModal = ({
  orderId,
  retailCountry,
  financingType,
  initialClassification,
  onDocumentsRequested,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const popupContainerRef = useRef<HTMLDivElement>(null);
  const getPopupContainer = () =>
    popupContainerRef.current ?? window.document.body;
  const [isSubmitting, setSubmitting] = useState(false);
  const [formApi] = Form.useForm<FormValues>();
  const { data, isLoading } = useCategoriesData({
    retailCountry,
    financingType,
  });
  const createDocumentRequest = useCreateDocumentRequest({ orderId });
  const reasonOptions = useRequestDocumentReasonOptions();

  const requestReason = Form.useWatch("requestReason", formApi);
  const isInternalCommentRequired =
    requestReason === RequestDocumentReason.OTHER;

  async function onSubmit({
    documentSubTypeKey,
    comment,
    internalComment,
    requestReason,
  }: FormValues) {
    try {
      setSubmitting(true);
      const type = data?.typesByCategory[formApi.getFieldValue("category")];
      const documentTypeKey = type?.find(
        (x) => x.value === formApi.getFieldValue("type")
      )?.["data-qa-selector-option-key"]!;

      await createDocumentRequest({
        documentTypeKey,
        documentSubTypeKey,
        comment,
        internalComment,
        requestReason,
      });
      dispatchEvent("orderDocuments.documentRequest.created", null);
      onDocumentsRequested?.();
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Modal
      visible
      zIndex={1200}
      title={t("bo.orderDocuments.documentRequests.button.requestDocument")}
      footer={null}
      onCancel={onClose}
    >
      <Spin spinning={isLoading}>
        <Form
          form={formApi}
          layout="vertical"
          initialValues={{
            ...initialClassification,
          }}
          validateMessages={{
            required: t("bo.orderClaims.form.validation.canNotBeEmpty"),
          }}
          data-qa-selector="requestDocumentForm"
          onFinish={onSubmit}
        >
          <ClassificationFields
            formApi={formApi}
            categories={data?.categories ?? []}
            typesByCategory={data?.typesByCategory ?? {}}
            subTypesByType={data?.subTypesByType ?? {}}
          />
          <Form.Item
            name="requestReason"
            label={t("bo.orderDocuments.requestDocuments.form.field.reason")}
            required
            rules={[{ required: true }]}
          >
            <Select
              placeholder={t("bo.common.field.placeholder.selectValue")}
              options={reasonOptions}
              data-qa-selector="requestDocumentReasonSelect"
              dropdownClassName="QA_ID_dropdown_reason"
              getPopupContainer={getPopupContainer}
            />
          </Form.Item>
          <Form.Item
            name="comment"
            label={t("bo.orderDocuments.requestDocuments.form.field.comment")}
          >
            <TextArea data-qa-selector="requestDocumentComment" />
          </Form.Item>
          <Form.Item
            name="internalComment"
            label={t(
              "bo.orderDocuments.requestDocuments.form.field.internalComment"
            )}
            required={isInternalCommentRequired}
            rules={[{ required: isInternalCommentRequired }]}
          >
            <TextArea data-qa-selector="requestDocumentInternalComment" />
          </Form.Item>
          <Space style={{ justifyContent: "end", width: "100%" }} size="large">
            <Button
              onClick={onClose}
              data-qa-selector="requestDocumentCancelButton"
            >
              {t("bo.common.button.cancel")}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              loading={isSubmitting}
              data-qa-selector="requestDocumentRequestButton"
            >
              {t("bo.orderDocuments.requestDocuments.form.button.request")}
            </Button>
          </Space>
        </Form>
        <div ref={popupContainerRef} />
      </Spin>
    </Modal>
  );
};
