// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZW8OF0JtHosBAV5egOBQ.ZW8OF0JtHosBAV5egOBQ,\n.ZW8OF0JtHosBAV5egOBQ.ZW8OF0JtHosBAV5egOBQ:hover {\n  color: white;\n  background-color: black;\n  outline-color: black;\n}\n", "",{"version":3,"sources":["webpack://./src/components/CellVerification/styles.less"],"names":[],"mappings":"AACE;;EAEE,YAAA;EACA,uBAAA;EACA,oBAAA;AAAJ","sourcesContent":[".verifyButton {\n  &&,\n  &&:hover {\n    color: white;\n    background-color: black;\n    outline-color: black;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verifyButton": "ZW8OF0JtHosBAV5egOBQ"
};
export default ___CSS_LOADER_EXPORT___;
