import { ClientError, GraphQLClient } from "graphql-request";

export const graphqlClient = new GraphQLClient(
  "/api/v1/retail-graphql-gateway"
);

const originalRequest = graphqlClient.request;

// @ts-ignore
graphqlClient.request = async (...args) => {
  try {
    // @ts-ignore
    return await originalRequest.apply(graphqlClient, args);
  } catch (e: any) {
    // our GraphQL api returns "errors" field with empty array.
    // `graphql-request` library see this as failed request and throws error
    // so we have to recover in this way
    if (e instanceof ClientError && e.response.errors?.length === 0) {
      return e.response.data;
    }

    throw e;
  }
};
