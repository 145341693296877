// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NJGygix5LqwqkEJkt5x3 {\n  display: flex;\n  justify-items: stretch;\n  gap: 16px;\n}\n.X4DFUYMXSQvi0orxk1qs {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DocumentViewer/FormCard/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAAF","sourcesContent":[".footer {\n  display: flex;\n  justify-items: stretch;\n  gap: 16px;\n}\n\n.section {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "NJGygix5LqwqkEJkt5x3",
	"section": "X4DFUYMXSQvi0orxk1qs"
};
export default ___CSS_LOADER_EXPORT___;
