import { Nil } from "@/types";
import { PATHS } from "@retail/backoffice-urls";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";

interface Props {
  reusesVerificationFromOrderId: string | Nil;
  reusesVerificationFromOrderNumber: string | Nil;
  language: string;
}

export const DocumentsTakenFromLink = ({
  reusesVerificationFromOrderId,
  reusesVerificationFromOrderNumber,
  language,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {reusesVerificationFromOrderId ? (
        <span>
          {t(
            "bo.ordersPage.orderDetails.tabs.verification.section.documents.documentsTakenFrom"
          )}
          :{" "}
          <a
            href={`${generatePath(PATHS.ORDER_VERIFICATION_SUBTAB, {
              orderId: reusesVerificationFromOrderId,
              language,
            })}?scrollTo=verification`}
          >
            {reusesVerificationFromOrderNumber}
          </a>
        </span>
      ) : (
        t(
          "bo.ordersPage.orderDetails.tabs.verification.section.documents.header"
        )
      )}
    </>
  );
};
