import { ClassificationFields } from "@/components/ClassificationFields";
import { OrderFinancingType } from "@/generated/graphql";
import { useCategoriesData } from "@/hooks/useCategoriesData";
import { Nil } from "@/types";
import { ClassificationModel } from "@/types/ClassificationModel";
import {
  Button,
  Form,
  Modal,
  notification,
  Space,
  Spin,
  UploadFile,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FileUpload } from "./FileUpload";
import { useCreateDocuments } from "./useCreateDocuments";

export interface FormValues extends ClassificationModel {
  files: UploadFile[];
}

interface AddDocumentModalProps {
  orderId: string;
  retailCountry: string;
  financingType: OrderFinancingType | Nil;
  onClose: () => void;
  onDocumentsCreated: () => void;
}

export const AddDocumentModal = ({
  retailCountry,
  financingType,
  orderId,
  onDocumentsCreated,
  onClose,
}: AddDocumentModalProps) => {
  const { t } = useTranslation();
  const categoriesData = useCategoriesData({
    retailCountry,
    financingType,
  });

  const createDocuments = useCreateDocuments(orderId);
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = async ({ type, documentSubTypeKey, files }: FormValues) => {
    try {
      setSubmitting(true);
      await createDocuments({
        documentSubTypeKey: documentSubTypeKey!,
        documentTypeId: type!,
        files,
      });
      onDocumentsCreated();
      notification.success({
        message: t("bo.orderDocuments.dragAndDropLabel.successMessage"),
        duration: 2,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const [formApi] = Form.useForm<FormValues>();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const categoryParam = params.get("category");
    const typeParam = params.get("type");

    if (categoriesData.data && categoryParam && typeParam) {
      const { categories, typesByCategory } = categoriesData.data;
      const categoryValue = atob(categoryParam);

      if (categories.some((it) => it.value === categoryValue)) {
        formApi.setFieldsValue({ category: categoryValue });

        const typeValue = atob(typeParam);
        const type = typesByCategory[categoryValue]?.find(
          (it) => it.label === typeValue
        );
        if (type) {
          formApi.setFieldsValue({ type: type.value });
        }
      }
    }
  }, [categoriesData.data, formApi]);

  return (
    <Modal visible footer={null} onCancel={onClose}>
      <Spin spinning={categoriesData.isLoading}>
        <Form
          form={formApi}
          layout="vertical"
          validateMessages={{
            required: t("bo.common.form.field.required"),
          }}
          data-qa-selector="documentUploadForm"
          onFinish={onSubmit}
        >
          <ClassificationFields
            formApi={formApi}
            categories={categoriesData.data?.categories ?? []}
            typesByCategory={categoriesData.data?.typesByCategory ?? {}}
            subTypesByType={categoriesData.data?.subTypesByType ?? {}}
          />
          <FileUpload />
          <Space style={{ justifyContent: "end", width: "100%" }} size="large">
            <Button
              onClick={onClose}
              data-qa-selector="documentUploadCancelButton"
            >
              {t("bo.common.button.cancel")}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              loading={isSubmitting}
              data-qa-selector="documentUploadSaveButton"
            >
              {t("bo.common.button.save")}
            </Button>
          </Space>
        </Form>
      </Spin>
    </Modal>
  );
};
