import {
  ExclamationCircleOutlined,
  InboxOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { Form, Modal, Space, UploadFile } from "antd";
import { useState } from "react";

import {
  DOCUMENT_FILE_TYPES,
  FILE_SIZE_MAX_MB,
  MEGABYTES_TO_BYTES_MULTIPLIER,
} from "@/utils/files";
import { UploadControl } from "./UploadControl";

import { useTranslation } from "react-i18next";
import cn from "./styles.less";

const acceptedFileMimeTypes = DOCUMENT_FILE_TYPES.map((type) => type.mime);

const AcceptedFilesHint = () => {
  const { t } = useTranslation();

  return (
    <>
      {t("bo.orderDocuments.allowedFormats", {
        formats: DOCUMENT_FILE_TYPES.map((type) => type.ext).join(", "),
      })}
      .
      <br />
      {t("bo.orderDocuments.maxSize", { size: FILE_SIZE_MAX_MB })}
    </>
  );
};

const OversizedFilesModalContent = ({ files }: { files: UploadFile[] }) => (
  <Space align="start" size="large">
    <ExclamationCircleOutlined className={cn.errorIcon} />
    <Space direction="vertical">
      <Space direction="vertical" size="small">
        {files.map((file) => (
          <Space key={file.uid}>
            <PaperClipOutlined className={cn.errorFileIcon} />
            {file.name}
          </Space>
        ))}
      </Space>
      <div className={cn.errorFileHint}>
        <AcceptedFilesHint />
      </div>
    </Space>
  </Space>
);

export const FileUpload = () => {
  const [oversizedFiles, setOversizedFiles] = useState<UploadFile[]>([]);
  const { t } = useTranslation();

  const onOversizedFileSelected = (file: UploadFile) =>
    setOversizedFiles((files) => [...files, file]);

  const onCloseErrorModal = () => setOversizedFiles([]);

  return (
    <>
      <Form.Item
        name="files"
        label="Files"
        required
        rules={[{ required: true }]}
      >
        <UploadControl
          accept={acceptedFileMimeTypes}
          maxFileSizeBytes={FILE_SIZE_MAX_MB * MEGABYTES_TO_BYTES_MULTIPLIER}
          onOversizedFileSelected={onOversizedFileSelected}
          data-qa="dropzone-field"
        >
          <Space direction="vertical">
            <InboxOutlined className={cn.uploadIcon} />
            <span className={cn.uploadHint}>
              {t("bo.orderDocuments.dragAndDropLabel")}
            </span>
            <span className={cn.uploadAllowedFiles}>
              <AcceptedFilesHint />
            </span>
          </Space>
        </UploadControl>
      </Form.Item>
      {oversizedFiles.length > 0 && (
        <Modal
          visible
          closable={false}
          title={t("bo.orderDocuments.dragAndDropLabel.validationMessage")}
          cancelButtonProps={{ style: { display: "none" } }}
          onOk={onCloseErrorModal}
        >
          <OversizedFilesModalContent files={oversizedFiles} />
        </Modal>
      )}
    </>
  );
};
