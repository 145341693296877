import { PopConfirm } from "@/components/PopConfirm";
import { DeleteFilled } from "@ant-design/icons";
import { Button } from "antd";
import { ComponentProps } from "react";
import { useTranslation } from "react-i18next";

interface Props extends ComponentProps<typeof Button> {
  onConfirm: () => Promise<unknown>;
}

export const DocumentRequestDeleteConfirmation = ({
  onConfirm,
  ...props
}: Props) => {
  const { t } = useTranslation();

  return (
    <PopConfirm
      title={t(
        "bo.orderDocuments.documentRequests.requestDeleteConfirmation.title"
      )}
      onConfirm={onConfirm}
    >
      <Button type="primary" danger icon={<DeleteFilled />} {...props} />
    </PopConfirm>
  );
};
