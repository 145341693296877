import { getApiClient } from "@/apiClient";
import { FEATURE } from "@/constants/feature";
import { Nil } from "@/types";
import { getDefaultLocaleOfCountry } from "@retail/i18n/utils";
import { useMemo } from "react";
import { useQuery } from "react-query";

const getEnvironment = () =>
  (typeof __config === "object" && __config?.APP_ENV?.toUpperCase()) ||
  process.env?.ENV?.toUpperCase();

type FeaturesAsyncParams = {
  names: FEATURE[];
  country: string | Nil;
  featureUsageDate?: string;
};

export const useFeatures = ({
  names,
  country,
  featureUsageDate,
}: FeaturesAsyncParams) => {
  const queryKey = [
    "RetailFeaturesStatusV2",
    country,
    featureUsageDate,
    ...names,
  ].filter(Boolean);

  const { data, isLoading } = useQuery(
    queryKey,
    () =>
      getApiClient().RetailFeaturesStatusV2({
        names,
        context: {
          country,
          locale: country ? getDefaultLocaleOfCountry(country) : null,
          environment: getEnvironment(),
          featureUsageDate,
        },
      }),
    {
      enabled: Boolean(country && names.length),
      staleTime: Infinity,
    }
  );

  const isFeatureEnabled = useMemo(() => {
    const featuresSet = new Map<FEATURE, boolean>();

    data?.retailFeaturesStatusV2?.forEach((status) => {
      if (status) {
        const { name, enabled } = status ?? {};
        featuresSet.set(name as FEATURE, enabled);
      }
    });

    return (feature: FEATURE) => featuresSet.get(feature);
  }, [data]);

  return {
    isLoading,
    isFeatureEnabled,
  };
};
