import { LabeledRow } from "@/components/LabeledRow";
import { OrderDocumentDetailsModel } from "@/types/OrderDocumentDetailsModel";
import { formatDate } from "@/utils/datetime";
import { formatCurrency } from "@retail/backoffice-ui/src/utils/formatCurrency";
import { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { BorrowerName } from "./BorrowerName";
import { Income } from "./Income";

interface Props
  extends Pick<
      OrderDocumentDetailsModel,
      "borrower" | "currencyCode" | "coBorrowerName"
    >,
    ComponentProps<"div"> {}

export const Borrower = ({
  borrower,
  currencyCode,
  coBorrowerName,
  ...props
}: Props) => {
  const { t } = useTranslation();

  if (!borrower) {
    return null;
  }

  const {
    jobTitle,
    employmentStartDate,
    primaryIncomeMinorUnits,
    additionalIncomeMinorUnits,
    sector,
  } = borrower ?? {};

  const employmentDetailsText = [
    borrower?.employmentStatus,
    jobTitle,
    formatDate(employmentStartDate),
  ]
    .filter(Boolean)
    .join(", ");

  const primaryIncome =
    Number.isFinite(primaryIncomeMinorUnits) && currencyCode
      ? formatCurrency({
          amountMinorUnits: primaryIncomeMinorUnits,
          currencyCode,
        })
      : null;
  const secondaryIncome =
    Number.isFinite(additionalIncomeMinorUnits) && currencyCode
      ? formatCurrency({
          amountMinorUnits: additionalIncomeMinorUnits,
          currencyCode,
        })
      : null;

  return (
    <div {...props}>
      <BorrowerName borrowerName={coBorrowerName} />
      {employmentDetailsText ? (
        <div data-qa-selector="employmentDetails">{employmentDetailsText}</div>
      ) : null}
      <Income
        data-qa-selector="primaryIncome"
        label={t(
          "bo.orderDocuments.viewer.orderDetails.employmentDetails.primaryIncome"
        )}
        value={primaryIncome}
      />
      <Income
        data-qa-selector="secondaryIncome"
        label={t(
          "bo.orderDocuments.viewer.orderDetails.employmentDetails.secondaryIncome"
        )}
        value={secondaryIncome}
      />
      {sector && (
        <LabeledRow
          label={t(
            "bo.orderDocuments.viewer.orderDetails.employmentDetails.sector"
          )}
        >
          {t(
            `bo.orderDocuments.viewer.orderDetails.employmentDetails.sector.${sector}`
          )}
        </LabeledRow>
      )}
    </div>
  );
};
