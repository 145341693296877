import { ANTD_EXTERNAL_VERSION_LESS } from "@/antdConstants";
import { notification } from "antd";
import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { SummaryVerificationDocuments } from "./features/SummaryVerificationDocuments";
import Root from "./root.component";

if (__LOAD_BOOTSTRAP__) {
  /**
   We need to include bootstrap's global styles to ensure that
   the page looks the same either in development mode or when embedded
   into the legacy BO (as it has these global styles)
   */
  require("bootswatch/cosmo/bootstrap.min.css");
}

if (__WEBPACK_DEV_SERVER__) {
  require("antd/dist/antd.less");
}

notification.config({
  top: 55,
  prefixCls: `${ANTD_EXTERNAL_VERSION_LESS}-notification`,
});

function errorBoundary(err: Error, info: React.ErrorInfo) {
  console.error(err, info);

  return <>Some error occurred!</>;
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  errorBoundary,
  rootComponent: Root,
});

export const { bootstrap, mount, unmount } = lifecycles;

export const orderSummaryVerificationDocuments = singleSpaReact({
  React,
  ReactDOM,
  errorBoundary,
  rootComponent: SummaryVerificationDocuments,
});

export { fetchDocumentRequestsCount } from "./features/DocumentRequestsCount";
