import { useTranslation } from "react-i18next";
import { COUNTRIES } from "@retail/i18n/constants";

import { getDeTemplates } from "./getDeTemplates";
import { DocumentTemplatesContext } from "./types";

interface UseDocumentTemplatesParams {
  context: DocumentTemplatesContext | null;
}

export const useDocumentTemplates = ({
  context,
}: UseDocumentTemplatesParams) => {
  const { t } = useTranslation();

  switch (context?.retailCountry) {
    case COUNTRIES.DE:
      return getDeTemplates(context, t);
    default:
      return [];
  }
};
