import { useRevokeDocumentRequest } from "@/hooks/useRevokeDocumentRequest";
import { DocumentRequestEntity } from "@/types/DocumentRequestEntity";
import { dispatchEvent } from "@retail/backoffice-events";
import { Table } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useColumns } from "./useColumns";

interface Props {
  documents: DocumentRequestEntity[];
  isLoading: boolean;
  onDelete: () => Promise<unknown>;
}

export const DocumentRequests = ({ documents, isLoading, onDelete }: Props) => {
  const { t } = useTranslation();
  const revokeDocumentRequest = useRevokeDocumentRequest();
  const handleDelete = useCallback(
    async (id: string) => {
      await revokeDocumentRequest({ id });
      await onDelete();
      dispatchEvent("orderDocuments.documentRequest.removed", null);
    },
    [onDelete, revokeDocumentRequest]
  );

  const columns = useColumns({ onDelete: handleDelete });

  return (
    <Table
      loading={isLoading}
      dataSource={documents}
      columns={columns}
      size="small"
      rowKey="id"
      locale={{
        emptyText: t(
          "bo.ordersPage.orderDetails.tabs.verification.section.documents.notFound"
        ),
      }}
      pagination={false}
    />
  );
};
