import { DocumentState } from "@/constants/DocumentState";
import { DocumentEntity } from "@/types/DocumentEntity";
import { MinusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ComponentProps, PropsWithChildren, ReactNode } from "react";
import cn from "./styles.less";

interface Props extends ComponentProps<typeof Button> {
  document: DocumentEntity;
  fallback?: ReactNode;
  onPreviewDocument: (id: string) => void;
}

export const BrowseButton = ({
  document,
  fallback = <MinusOutlined />,
  onPreviewDocument,
  children,
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <>
      {document.meta.isContentViewAllowed &&
      document.state !== DocumentState.HARD_DELETED ? (
        <Button
          {...props}
          type="link"
          className={cn.browseButton}
          onClick={() => onPreviewDocument(document.id)}
        >
          {children}
        </Button>
      ) : (
        fallback
      )}
    </>
  );
};
