import { AccessDomain } from "@/acl";
import { getApiClient } from "@/apiClient";
import { useMutation } from "react-query";

interface CallbackProps {
  documentSubTypeKey: string;
  documentTypeKey: string;
  id: string;
}

export function useUpdateDocumentClassification() {
  const { mutateAsync } = useMutation(
    getApiClient({ domain: AccessDomain.BackofficeManagement })
      .UpdateDocumentClassification
  );

  return mutateAsync;
}
