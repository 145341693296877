import { ClassificationFields } from "@/components/ClassificationFields";
import { DocumentTypeKey } from "@/constants/DocumentTypeKey";
import {
  OrderFinancingType,
  UpdateDocumentClassificationMutationVariables,
} from "@/generated/graphql";
import { useCategoriesData } from "@/hooks/useCategoriesData";
import { Nil } from "@/types";
import { ClassificationModel } from "@/types/ClassificationModel";
import { DocumentEntity } from "@/types/DocumentEntity";
import { Button, Card, Form, Spin } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "./styles.less";

interface Props extends ClassificationModel {
  retailCountry: string;
  financingType: OrderFinancingType | Nil;
  documentId: string | Nil;
  document?: DocumentEntity;
  onSubmit: (x: UpdateDocumentClassificationMutationVariables) => Promise<void>;
  onCancel: VoidFunction;
}

export const FormCard = ({
  category,
  type,
  documentSubTypeKey,
  retailCountry,
  financingType,
  documentId,
  document,
  onSubmit,
  onCancel,
}: Props) => {
  const { t } = useTranslation();
  const [formApi] = Form.useForm<ClassificationModel>();
  const { data, isLoading } = useCategoriesData({
    retailCountry,
    financingType,
    document,
  });
  const [isSubmiting, setIsSubmiting] = useState(false);

  async function handleSubmit() {
    setIsSubmiting(true);
    const type = data?.typesByCategory[formApi.getFieldValue("category")];
    const documentTypeKey = type?.find(
      (x) => x.value === formApi.getFieldValue("type")
    )?.["data-qa-selector-option-key"]!;

    try {
      await onSubmit({
        documentTypeKey,
        documentSubTypeKey: formApi.getFieldValue("documentSubTypeKey"),
        id: documentId!,
      });
    } finally {
      setIsSubmiting(false);
    }
  }

  return (
    <Card
      data-qa-selector="formCard"
      style={{ height: "100%" }}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Spin spinning={isLoading || isSubmiting}>
        <Form
          form={formApi}
          initialValues={{
            category,
            type,
            documentSubTypeKey,
          }}
          layout="vertical"
          className={cn.section}
          validateMessages={{
            required: t("bo.common.form.field.required"),
          }}
          data-qa-selector="documentUpdateForm"
          onFinish={handleSubmit}
        >
          <ClassificationFields
            formApi={formApi}
            categories={data?.categories ?? []}
            typesByCategory={data?.typesByCategory ?? {}}
            subTypesByType={data?.subTypesByType ?? {}}
          />
          <footer className={cn.footer}>
            <Button
              block
              size="large"
              type="primary"
              htmlType="submit"
              data-qa-selector="submitButton"
            >
              {t("bo.orderDocuments.viewer.form.button.ok")}
            </Button>
            {document?.documentType?.key !==
            DocumentTypeKey.ATTACHED_FROM_EMAIL_REPLY ? (
              <Button
                block
                size="large"
                data-qa-selector="cancelButton"
                onClick={onCancel}
              >
                {t("bo.orderDocuments.viewer.form.button.cancel")}
              </Button>
            ) : null}
          </footer>
        </Form>
      </Spin>
    </Card>
  );
};
