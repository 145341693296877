import {
  DocumentsByListType,
  ListType,
  Nil,
} from "@/features/DocumentsTab/types";
import { useSaveVisitingLog } from "@/hooks/useSaveVisitingLog";
import { DocumentEntity } from "@/types/DocumentEntity";
import { useState } from "react";

interface Props {
  orderId: string;
  documents: DocumentsByListType | Nil;
}

export const useDocumentPreview = ({ orderId, documents }: Props) => {
  const saveLogMutation = useSaveVisitingLog({ orderId });

  const [previewState, setPreviewState] = useState<{
    listType: ListType;
    initialId: string;
  } | null>(null);

  const onPreviewDocument = (id: string) => {
    const matchesById = (doc: DocumentEntity) => doc.id === id;

    const [listType, documentList] =
      Object.entries(documents ?? {}).find(([, list]) =>
        list.some(matchesById)
      ) ?? [];

    if (listType && documentList) {
      const doc = documentList.find(matchesById)!;
      saveLogMutation(doc.fullUrl);
      setPreviewState({
        listType: listType as ListType,
        initialId: id,
      });
    }
  };

  const onClosePreview = () => setPreviewState(null);

  return {
    documents: (previewState && documents?.[previewState.listType]) ?? [],
    initialId: previewState?.initialId,
    onPreviewDocument,
    onClosePreview,
  };
};
